import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import OfferItem from '../offer-item'

// show list of  portraits styles on Portrait page
const PortraitList = ({ portraits, selectItem, isB2C }) => {
  const oddList = portraits.filter((p, index) => index % 2 === 0)
  const evenList = portraits.filter((p, index) => index % 2 === 1)
  return (
    <Container>
      {/* Responsive */}
      <div className="d-block d-lg-none">
        {portraits?.map((p, index) => (
          <Row key={p.id} className="my-3">
            <Col>
              <OfferItem
                item={p}
                selectItem={() => selectItem(index)}
                showPrice={isB2C}
              />
            </Col>
          </Row>
        ))}
      </div>
      {/* Large Screen */}
      <Row className="d-none d-lg-flex my-3">
        <Col className="p-5">
          <Row className="offer-left-column">
            {oddList?.map((p, index) => {
              const isEven = index % 2 === 0
              const col = isEven
                ? 12
                : {
                    span: 8,
                    offset: 4,
                  }
              return (
                <Col lg={col} className="mb-4 p-0">
                  <OfferItem
                    item={p}
                    selectItem={() =>
                      selectItem(portraits.findIndex(po => po.id === p.id))
                    }
                    showPrice={isB2C}
                  />
                </Col>
              )
            })}
          </Row>
        </Col>
        <Col className="pl-lg-4 p-lg-0">
          <Row className="offer-right-column">
            {evenList?.map((p, index) => {
              const isEven = index % 2 === 0
              const col = isEven ? 8 : 12
              return (
                <Col
                  lg={col}
                  className={`${isEven ? 'p-0 mb-2' : 'my-4 mb-0 p-0'}`}
                >
                  <OfferItem
                    item={p}
                    selectItem={() =>
                      selectItem(portraits.findIndex(po => po.id === p.id))
                    }
                    showPrice={isB2C}
                  />
                </Col>
              )
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

PortraitList.propTypes = {
  portraits: propTypes.arrayOf(propTypes.object).isRequired,
  selectItem: propTypes.func.isRequired,
  isB2C: propTypes.bool,
}
PortraitList.defaultProps = { isB2C: false }

export default PortraitList
