import { Button, Col, Row } from 'react-bootstrap'
import React from 'react'
import propTypes from 'prop-types'
import ModalCard from '../../app-components/modal-card'
import Icon from '../../app-components/icon'

const CartWipeModal = ({ show, handleCancel, handleConfirm }) => {
  return (
    <ModalCard
      icon={
        <Icon name="icon-edit-picture" paths={4} iconClass="card-body-icon" />
      }
      title={
        <p>
          Attention, votre panier ne peut contenir qu&apos;un seul article.{' '}
          <br />
          Vous souhaitez ?
        </p>
      }
      show={show}
      heading="Nouveau Panier ?"
      className="product-cart-modal"
      onHide={handleCancel}
    >
      <Row className="text-center mb-0 pb-0 border-t-1 border-top">
        <Col xs={12} className="mt-3">
          <Button variant="warning" size="lg" onClick={handleCancel}>
            Voir votre panier actuel
          </Button>
        </Col>
        <Col xs={12} className="mt-3">
          <Button onClick={handleConfirm} variant="outline-primary" size="lg">
            Créer un nouveau panier
          </Button>
        </Col>
      </Row>
    </ModalCard>
  )
}
CartWipeModal.propTypes = {
  show: propTypes.bool.isRequired,
  handleCancel: propTypes.func.isRequired,
  handleConfirm: propTypes.func.isRequired,
}
export default CartWipeModal
