import React from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { Router } from '@reach/router'
import Steps, { Step } from 'rc-steps'
import propTypes from 'prop-types'
import upperFirst from 'lodash/upperFirst'
import dropRight from 'lodash/dropRight'
import PanelStyleItem from '../style-item'
import Heading from '../../../app-components/dashed-heading'
import FormSteps from '../_formSteps'
import OfferDropdownSection from '../../../sections/offerDropdown'
import RecapPanel from '../_recap'
import ConfirmPanel from '../_confirm'

import 'rc-steps/assets/index.css'
import './styles.scss'
import { STEPS_LABEL } from '../../../../constants/steps'

// large screen sidePanel
const DesktopPanel = ({
  data,
  productStyles,
  productType,
  productName,
  showPanel,
  togglePanel,
  showStylePanel,
  toggleStylePanel,
  STEPS,
  stepsContent,
  step,
  prevStep,
  nextStep,
  goStep,
  offer,
  totalPrice,
  selectOffer,
  values,
  setValue,
  product,
  handleInputChange,
  handleOptionChange,
  errors,
  register,
  validate,
  triggerValidation,
  handleSubmit,
  isDisabled,
  validForm,
  isB2C,
}) => {
  const renderSteps = () => {
    const steps = []
    Object.entries(STEPS).map(([key, value], index) => {
      const s = (
        <Step
          title={`${index + 1}. ${upperFirst(STEPS_LABEL[key].toLowerCase())}`}
          className="step"
          onClick={() => goStep(value)}
        />
      )
      return steps.push(s)
    })

    // remove Confirm (& Recap) Step from breadCrumb
    return dropRight(steps, productType === 'portrait' ? 2 : 1)
  }
  return (
    <Modal
      show={showPanel}
      onHide={togglePanel}
      dialogClassName="side-panel"
      backdropClassName="offer-overlay"
    >
      <Modal.Body bsPrefix="side-panel-body h-100 vertical-scroll">
        <Container className="h-100">
          <Row className="bg-primary h-100">
            {productStyles !== null && (
              <Col
                lg="auto"
                className="style-list pt-3 pl-2 d-flex flex-row h-100"
              >
                <div
                  className={`close-tab align-self-center ${
                    showStylePanel ? 'right' : 'left'
                  }`}
                >
                  <a className="text-white" onClick={toggleStylePanel}>
                    <span className="icon-arrow-down text-white" />
                  </a>
                </div>
                {showStylePanel && (
                  <div className="pt-4 pb-5 pr-4 pl-1 h-100">
                    <p className="text-center text-white font-weight-bold">
                      Style de {productType}
                    </p>
                    <div className="h-100 vertical-scroll">
                      {productStyles.map(s => (
                        <PanelStyleItem
                          item={s}
                          offer={offer}
                          select={selectOffer}
                          isB2C={isB2C}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </Col>
            )}
            <Col className="content h-100 vertical-scroll">
              <Row className="justify-content-end">
                <Col className="m-0 p-0" xs="auto">
                  <Button
                    className="border-0 rounded-0"
                    variant="primary"
                    size="lg"
                    onClick={togglePanel}
                  >
                    <span className="icon-close" />
                  </Button>
                </Col>
              </Row>
              <Form onSubmit={e => e.preventDefault()}>
                {step < STEPS.SUMMARY && (
                  <>
                    <Row className="pr-4">
                      <Col>
                        <Heading align="left" title={productName()} />
                      </Col>
                      {isB2C && (
                        <Col md="auto" className="mr-5">
                          <span className="price">{totalPrice()}€</span>
                        </Col>
                      )}
                    </Row>
                    <Row className="mb-3 pr-4">
                      <Col>
                        <Steps progressDot current={step} className="steps">
                          {renderSteps()}
                        </Steps>
                        <FormSteps
                          data={isB2C ? product.options : data}
                          offer={offer}
                          register={register}
                          errors={errors}
                          handleInputChange={handleInputChange}
                          handleOptionChange={handleOptionChange}
                          validate={validate}
                          triggerValidation={triggerValidation}
                          setValue={setValue}
                          STEPS={STEPS}
                          step={step}
                          stepsContent={stepsContent}
                          type={productType}
                          prevStep={prevStep}
                          nextStep={nextStep}
                          isDisabled={isDisabled}
                          validForm={validForm}
                          isB2C={isB2C}
                        />
                      </Col>
                    </Row>
                    {((isB2C && step < STEPS.BOOKING) ||
                  (!isB2C && step < STEPS.INFORMATIONS)) && (
                      <Row>
                        <Col>
                          <OfferDropdownSection
                            sections={data.accordionContent}
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                <Router basepath="/professionnels/:productType/">
                  <RecapPanel
                    path="recapitulatif"
                    type={productType}
                    data={data}
                    stepsContent={stepsContent}
                    offer={offer}
                    style={product}
                    values={values()}
                    goPrevious={() => goStep(0)}
                    handleSubmit={handleSubmit}
                  />
                </Router>
              </Form>
              <Router basepath="/professionnels/:productType/">
                <ConfirmPanel path="confirmation" offer={offer} />
              </Router>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

DesktopPanel.propTypes = {
  data: propTypes.arrayOf(propTypes.any).isRequired,
  productStyles: propTypes.arrayOf(propTypes.object).isRequired,
  productType: propTypes.string.isRequired,
  productName: propTypes.func.isRequired,
  showPanel: propTypes.bool.isRequired,
  togglePanel: propTypes.func.isRequired,
  showStylePanel: propTypes.bool.isRequired,
  toggleStylePanel: propTypes.func.isRequired,
  step: propTypes.number.isRequired,
  STEPS: propTypes.objectOf(propTypes.number).isRequired,
  stepsContent: propTypes.objectOf(propTypes.any).isRequired,
  prevStep: propTypes.func.isRequired,
  nextStep: propTypes.func.isRequired,
  goStep: propTypes.func.isRequired,
  offer: propTypes.objectOf(propTypes.object).isRequired,
  product: propTypes.objectOf(propTypes.object).isRequired,
  values: propTypes.func.isRequired,
  setValue: propTypes.func.isRequired,
  selectOffer: propTypes.func.isRequired,
  handleInputChange: propTypes.func.isRequired,
  handleOptionChange: propTypes.func.isRequired,
  handleSubmit: propTypes.func.isRequired,
  register: propTypes.func.isRequired,
  errors: propTypes.objectOf(propTypes.object).isRequired,
  validate: propTypes.objectOf(propTypes.object).isRequired,
  triggerValidation: propTypes.func.isRequired,
  isDisabled: propTypes.func.isRequired,
  validForm: propTypes.func.isRequired,
  isB2C: propTypes.bool.isRequired,
  totalPrice: propTypes.func.isRequired,
}

export default DesktopPanel
