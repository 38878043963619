import React from 'react'
import propTypes from 'prop-types'
import './styles.scss'

// style choice panel  item
const PanelStyleItem = ({ item, offer, select, isLast, isB2C }) => (
  <div
    key={item.id}
    className={`style-item mb-4 ${isLast ? '' : 'mr-3'} mr-lg-0 ${
      offer.style === item.id ||
      (offer.id !== undefined && offer.id === item.id)
        ? 'selected'
        : ''
    }`}
    onClick={() => select(item, isB2C)}
  >
    <img
      className="img-fluid"
      src={isB2C ? item.original_image : item.image}
      alt={`Style de ${item.type} ${item.name}`}
    />
  </div>
)

PanelStyleItem.defaultProps = {
  isLast: false,
  isB2C: false,
}

PanelStyleItem.propTypes = {
  item: propTypes.shape({
    id: propTypes.number,
    image: propTypes.string.isRequired,
    original_image: propTypes.string.isRequired,
    type: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
  }).isRequired,
  offer: propTypes.objectOf(propTypes.any).isRequired,
  select: propTypes.func.isRequired,
  isLast: propTypes.bool,
  isB2C: propTypes.bool,
}

export default PanelStyleItem
