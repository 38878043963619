import React from 'react'
import { Button, Col, Container, Form, Modal, Nav, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import Slider from 'react-slick'

import dropRight from 'lodash/dropRight'
import { Router } from '@reach/router'
import Heading from '../../../app-components/dashed-heading'
import FormSteps from '../_formSteps'
import OfferDropdownSection from '../../../sections/offerDropdown'
import ConfirmPanel from '../_confirm'
import PanelStyleItem from '../style-item'
import RecapPanel from '../_recap'

import './styles.scss'
import { STEPS_LABEL } from '../../../../constants/steps'
import Icon from '../../../app-components/icon'

const sliderSettings = {
  variableWidth: true,
  dots: false,
  arrows: false,
  infinite: false,
  slidesToScroll: 1,
  rows: 1,
}

const MobilePanel = ({
  data,
  productStyles,
  productType,
  productName,
  showPanel,
  togglePanel,
  showStylePanel,
  toggleStylePanel,
  STEPS,
  stepsContent,
  step,
  prevStep,
  nextStep,
  goStep,
  offer,
  selectOffer,
  values,
  setValue,
  product,
  handleInputChange,
  handleOptionChange,
  handleSubmit,
  errors,
  validate,
  triggerValidation,
  register,
  isDisabled,
  validForm,
  isB2C,
  totalPrice,
}) => {
  const renderSteps = () => {
    const steps = []
    Object.entries(STEPS).map(([key, value]) => {
      const s = (
        <Nav.Item>
          <Nav.Link
            eventKey={value}
            onClick={() => goStep(value)}
            disabled={step < value}
          >
            <Icon
              name={`icon-${key.toLowerCase()}`}
              iconClass="d-block"
              paths={key === 'OPTIONS' ? '3' : ''}
            />
            <span className="text-uppercase">{STEPS_LABEL[key]}</span>
          </Nav.Link>
        </Nav.Item>
      )
      return steps.push(s)
    })
    // remove Confirm (& Recap) Step from breadCrumb
    return dropRight(steps, productType === 'portrait' ? 3 : 1)
  }

  const renderNav = () => (
    <div className="bg-primary position-fixed fixed-bottom h-auto p-0">
      {productStyles !== null && (
        <Container fluid>
          <Row className="p-3">
            <Col>
              <span
                className="text-white text-uppercase font-weight-bold"
                onClick={toggleStylePanel}
              >
                Style de {productType}
              </span>
            </Col>
            <Col xs="auto">
              <div className={`style-panel-icon ${showStylePanel ? '' : 'up'}`}>
                <span className="icon-arrow-down" onClick={toggleStylePanel} />
              </div>
            </Col>
          </Row>
          {showStylePanel && (
            <Slider {...sliderSettings} style={{ BackgroundColor: 'blue' }}>
              {productStyles.map((s, index, arr) => (
                <PanelStyleItem
                  key={s.id}
                  item={s}
                  offer={offer}
                  select={selectOffer}
                  isLast={index === arr.length - 1}
                />
              ))}
            </Slider>
          )}
        </Container>
      )}
      <Nav justify activeKey={step} className="mobile-panel-nav bg-white">
        <div
          className={`breadcrumb-mobile-nav-tab${
            productType === 'portrait' ? '-portrait' : ''
          } move-${productType === 'portrait' ? 'portrait-' : ''}to-${step}`}
        />
        <div
          className={`breadcrumb-mobile-nav-circle move-${
            productType === 'portrait' ? 'portrait-' : ''
          }to-${step}`}
        />
        {renderSteps()}
      </Nav>
    </div>
  )

  const showNav = () => {
    if (isB2C) {
      if (productType === 'portrait') {
        if (step < STEPS.BOOKING) {
          return renderNav()
        }
      }
      // todo B2C reportage/animation
      return null
    }

    if (productType === 'portrait') {
      if (step < STEPS.INFORMATIONS) {
        return renderNav()
      }
    }
    if (step < STEPS.CONFIRM) {
      return renderNav()
    }
    return null
  }

  return (
    <Modal
      show={showPanel}
      onHide={togglePanel}
      dialogClassName="mobile-panel"
      backdropClassName="offer-overlay"
    >
      <Modal.Body className="p-0 bg-white">
        <Container fluid>
          <Row className="justify-content-end">
            {isB2C && (
              <Col cols="auto" className="d-flex align-items-end pl-4">
                <span className="price">{totalPrice()}€</span>
              </Col>
            )}
            <Col className="m-0 p-0" xs="auto">
              <Button
                className="border-0 rounded-0"
                variant="primary"
                size="lg"
                onClick={togglePanel}
              >
                <span className="icon-close" />
              </Button>
            </Col>
          </Row>
          <Form onSubmit={e => e.preventDefault()}>
            {step < STEPS.SUMMARY && (
              <div className="mobile-panel-content pt-2">
                <Row>
                  <Col>
                    <Heading align="left" title={productName()} />
                  </Col>
                </Row>
                <FormSteps
                  data={isB2C ? product.options : data}
                  offer={offer}
                  register={register}
                  errors={errors}
                  handleInputChange={handleInputChange}
                  handleOptionChange={handleOptionChange}
                  validate={validate}
                  setValue={setValue}
                  STEPS={STEPS}
                  stepsContent={stepsContent}
                  step={step}
                  type={productType}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  isDisabled={isDisabled}
                  isMobile
                  validForm={validForm}
                  triggerValidation={triggerValidation}
                  isB2C={isB2C}
                />
                {((isB2C && step < STEPS.BOOKING) ||
                  (!isB2C && step < STEPS.INFORMATIONS)) && (
                  <Row>
                    <Col>
                      <OfferDropdownSection sections={data.accordionContent} />
                    </Col>
                  </Row>
                )}
              </div>
            )}
            <div className="mobile-panel-content pt-2">
              <Router basepath="/professionnels/:productType/">
                <RecapPanel
                  path="recapitulatif"
                  type={productType}
                  data={data}
                  stepsContent={stepsContent}
                  offer={offer}
                  style={product}
                  values={values()}
                  goPrevious={() => goStep(0)}
                  handleSubmit={handleSubmit}
                  isMobile
                />
              </Router>
            </div>
          </Form>
          <Router basepath="/professionnels/:productType/">
            <ConfirmPanel path="confirmation" offer={offer} />
          </Router>
        </Container>
        {showNav()}
      </Modal.Body>
    </Modal>
  )
}

MobilePanel.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
  productStyles: propTypes.arrayOf(propTypes.object).isRequired,
  productType: propTypes.string.isRequired,
  productName: propTypes.func.isRequired,
  showPanel: propTypes.bool.isRequired,
  togglePanel: propTypes.func.isRequired,
  showStylePanel: propTypes.bool.isRequired,
  toggleStylePanel: propTypes.func.isRequired,
  STEPS: propTypes.objectOf(propTypes.number).isRequired,
  stepsContent: propTypes.objectOf(propTypes.any).isRequired,
  step: propTypes.number.isRequired,
  prevStep: propTypes.func.isRequired,
  nextStep: propTypes.func.isRequired,
  goStep: propTypes.func.isRequired,
  offer: propTypes.objectOf(propTypes.any).isRequired,
  product: propTypes.objectOf(propTypes.any).isRequired,
  values: propTypes.func.isRequired,
  setValue: propTypes.func.isRequired,
  selectOffer: propTypes.func.isRequired,
  handleInputChange: propTypes.func.isRequired,
  handleOptionChange: propTypes.func.isRequired,
  handleSubmit: propTypes.func.isRequired,
  register: propTypes.func.isRequired,
  errors: propTypes.objectOf(propTypes.object).isRequired,
  validate: propTypes.func.isRequired,
  isDisabled: propTypes.func.isRequired,
  validForm: propTypes.func.isRequired,
  isB2C: propTypes.bool.isRequired,
  totalPrice: propTypes.func.isRequired,
  triggerValidation: propTypes.func.isRequired,
}

export default MobilePanel
