import React from 'react'
import propTypes from 'prop-types'
import Slider from 'react-slick'
import ChoiceItem from '../choice-item'
import Icon from '../../../app-components/icon'
import './styles.scss'

let settings = {
  variableWidth: true,
  dots: false,
  infinite: false,
  lazyLoad: false,
  slidesToScroll: 1,
  rows: 1,
}
const SliderArrow = ({ className, onClick, isNext }) => (
  <div className={`form-step-icon ${className} `} onClick={onClick}>
    <Icon name={`icon-slider-arrow-${isNext ? 'right' : 'left'}`} />
  </div>
)
SliderArrow.propTypes = {
  className: propTypes.string,
  onClick: propTypes.func,
  isNext: propTypes.bool,
}

SliderArrow.defaultProps = {
  className: '',
  onClick: () => {},
  isNext: false,
}

const FormStep = ({
  step,
  title,
  data,
  stepsContent,
  type,
  handleChange,
  offer,
  isB2C,
}) => {
  settings =
    type === 'format'
      ? {
          ...settings,
          arrows: true,
          nextArrow: <SliderArrow isNext />,
          prevArrow: <SliderArrow />,
        }
      : { ...settings, arrows: false }

  const renderItems = () => {
    const items = []
    let optionList = data
    if (data === null) return <p>Pas d&apos;options disponibles</p>
    if (isB2C) optionList = data?.option_value
    optionList.forEach((item, index, arr) =>
      items.push(
        <ChoiceItem
          offer={offer}
          optionId={isB2C ? data.product_option_id : data.id}
          item={item}
          index={index}
          stepsContent={stepsContent}
          key={item[`${type}_id`]}
          type={type}
          handleChange={handleChange}
          isLast={index === arr.length - 1}
          isB2C={isB2C}
        />
      )
    )
    return items
  }

  return (
    <div>
      <h3 className="step-title">
        <span className="text-yellow">{step + 1}.</span> {title} :
      </h3>
      {type === 'option' ? (
        renderItems()
      ) : (
        <Slider {...settings}>{renderItems()}</Slider>
      )}
    </div>
  )
}

FormStep.propTypes = {
  data: propTypes.arrayOf(propTypes.object).isRequired,
  stepsContent: propTypes.objectOf(propTypes.any).isRequired,
  handleChange: propTypes.func.isRequired,
  offer: propTypes.objectOf(propTypes.any).isRequired,
  step: propTypes.number.isRequired,
  title: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  isB2C: propTypes.bool,
}
FormStep.defaultProps = { isB2C: false }

export default FormStep
