import { Button, Col, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import every from 'lodash/every'
import Icon from '../icon'
import daysHeaders from '../../../constants/days'
import LoadingIndicator from '../loading-indicator'
import defaultTimeslots from '../../../constants/timeslots'
import './styles.scss'

const BookingCalendar = ({
  loading,
  month,
  nextMonth,
  prevMonth,
  setTime,
  setDate,
  selectedStudio,
  selectedDate,
  timeslots,
  isB2C,
}) => {
  // add spaces before first day if not a Monday
  const emptyDays = date => {
    const spaces = []
    const day = date.day() === 0 ? 7 : date.day() // overcome month starting a sunday : date.day() == 0

    for (let i = 1; i < day; i += 1) {
      spaces.push(<div />)
    }
    return spaces
  }

  // return disabled calendar
  const emptyCalendar = selectedMonth => {
    const days = [...emptyDays(selectedMonth.date(1))]
    for (let i = 1; i <= selectedMonth.daysInMonth(); i += 1)
      days.push(
        <div>
          <button disabled type="button">
            {i}
          </button>
        </div>
      )
    return days
  }

  const renderDays = () => {
    // return calendar view
    return Object.entries(timeslots).map(([day, times], index) => {
      const date = moment(day, 'YYYY-MM-DD')
      const isDisabled =
        times === null ||
        every(times, t => t === false) ||
        date.isBefore(moment().add(1, 'days'), 'day')
      return (
        <>
          {index === 0 && emptyDays(date)}
          <div>
            <button
              disabled={isDisabled}
              type="button"
              className={
                !isDisabled &&
                selectedDate !== null &&
                selectedDate.isSame(date, 'day')
                  ? 'selected'
                  : ''
              }
              onClick={() => setDate(date)}
            >
              {date.date()}
            </button>
          </div>
        </>
      )
    })
  }
  const renderTimeslots = () => {
    if (
      moment.isMoment(selectedDate) &&
      timeslots?.[selectedDate?.format('YYYY-M-D')]
    ) {
      return Object.keys(timeslots?.[selectedDate?.format('YYYY-M-D')]).map(
        (time, index) => {
          const isDisabled =
            !timeslots[selectedDate.format('YYYY-M-D')][time] ||
            selectedDate.isBefore(moment(), 'day')
          return (
            <div className={`${isB2C ? 'col-md-2 col-sm-3' : 'col-4'}`}>
              <button
                data-key={index}
                disabled={isDisabled}
                type="button"
                className={`one-timeslot ${
                  !isDisabled && selectedDate.format('HH[H]mm') === time
                    ? 'selected'
                    : ''
                }`}
                onClick={() => setTime(time)}
              >
                {time}
              </button>
            </div>
          )
        }
      )
    }
    // constant list
    return defaultTimeslots.map(time => (
      <div className={`${isB2C ? 'col-md-2 col-sm-3' : 'col-4'}`}>
        <button disabled type="button" className="one-timeslot">
          {time}
        </button>
      </div>
    ))
  }

  return (
    <div
      className={`calendar-and-date-selector ${
        !selectedStudio ? 'disabled' : ''
      }`}
    >
      <Row className="row row-calendar">
        <Col sm={isB2C ? 12 : 8} className="calendar-left-column">
          <div className="calendar-wrapper">
            <div className="calendar-top-row">
              <div className="row">
                <div className="col-4 calendar-side-column">
                  <Button variant="text" onClick={prevMonth}>
                    <Icon name="icon-slider-arrow-left" />
                  </Button>
                </div>
                <div className="col-4 calendar-middle-column">
                  <div className="calendar-current-month">
                    {month.format('MMMM YYYY')}
                  </div>
                </div>
                <div className="col-4 calendar-side-column">
                  <Button variant="text" onClick={nextMonth}>
                    <Icon name="icon-slider-arrow-right" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="calendar">
              <div className="calendar-headers">
                {daysHeaders.map(header => (
                  <div>{header}</div>
                ))}
              </div>
              <div className="calendar-days">
                {moment.isMoment(month) && selectedStudio
                  ? renderDays()
                  : emptyCalendar(month)}
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={isB2C ? 12 : 4}
          className={`${isB2C ? 'mt-3' : ''} calendar-right-column`}
        >
          <div
            className={`timeslot-selector-wrapper ${
              isB2C ? 'mw-100 w-100' : ''
            } `}
          >
            <div className="timeslot-selector-header">Créneaux horaires</div>
            <div className="timeslot-selector">
              <div className="row">{renderTimeslots()}</div>
            </div>
          </div>
        </Col>
      </Row>
      {loading && <LoadingIndicator className="booking-loader" />}
    </div>
  )
}

BookingCalendar.propTypes = {
  selectedStudio: propTypes.string,
  selectedDate: propTypes.objectOf(propTypes.any),
  month: propTypes.objectOf(propTypes.any),
  prevMonth: propTypes.func.isRequired,
  nextMonth: propTypes.func.isRequired,
  setTime: propTypes.func.isRequired,
  setDate: propTypes.func.isRequired,
  timeslots: propTypes.objectOf(propTypes.array).isRequired,
  loading: propTypes.bool,
  isB2C: propTypes.bool,
}
BookingCalendar.defaultProps = {
  selectedStudio: '',
  month: moment(),
  selectedDate: moment(),
  loading: false,
  isB2C: false,
}

export default BookingCalendar
