import propTypes from 'prop-types'
import React from 'react'
import OfferProductHeader from './OfferProductHeader'
import PortraitList from '../portrait-list'
import { styles as presetsB2B } from '../../../mock/products'
import Services from '../../sections/services'
import Gallery from '../../sections/gallery'
import PortfolioSlider from '../../sections/portfolio/slider'

const OfferProductPage = ({
  productType,
  acf,
  title,
  selectItem,
  presetsB2C,
  isB2C,
}) => (
  <>
    <OfferProductHeader
      isB2C={isB2C}
      title={title}
      togglePanel={() => selectItem(0)}
      acf={acf}
    />
    {productType === 'reportage' || productType === 'animation' ? (
      <>
        <Gallery galleries={acf.gallery} />
        {/* Block services */}
        {parseInt(acf.displayServices, 10) === 1 && acf.services && (
          <Services services={acf.services} />
        )}
        {/* Block offers or realizations slider */}

        <PortfolioSlider title="Découvrez toutes nos offres" />

        {/* Block icons2 */}
        {parseInt(acf.displayServices2, 10) === 1 && acf.servicesBlock2 && (
          <Services services={acf.servicesBlock2} />
        )}
      </>
    ) : (
      <>
        <PortraitList
          portraits={isB2C ? presetsB2C : presetsB2B}
          selectItem={selectItem}
          isB2C={isB2C}
        />
        {parseInt(acf.displayServices, 10) === 1 && acf.services && (
          <Services services={acf.services} />
        )}
        {parseInt(acf.displayServices2, 10) === 1 && acf.servicesBlock2 && (
          <Services services={acf.servicesBlock2} />
        )}
      </>
    )}
  </>
)

OfferProductPage.propTypes = {
  productType: propTypes.string,
  acf: propTypes.objectOf(propTypes.any).isRequired,
  title: propTypes.string.isRequired,
  selectItem: propTypes.func.isRequired,
  isB2C: propTypes.bool,
  presetsB2C: propTypes.arrayOf(propTypes.object),
}

OfferProductPage.defaultProps = {
  productType: '',
  isB2C: false,
  presetsB2C: [],
}

export default OfferProductPage
