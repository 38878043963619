export default [
  '09H15',
  '09H45',
  '10H15',
  '10H45',
  '11H15',
  '11H45',
  '12H15',
  '12H45',
  '14H15',
  '14H45',
  '15H15',
  '15H45',
  '16H15',
  '16H45',
  '17H15',
  '17H45',
  '18H15',
]
