/* eslint-disable  react/prop-types, camelcase */
import React from 'react'
import { Container } from 'react-bootstrap'
import Masonry from 'react-masonry-component'

import './styles.scss'

const masonryOptions = {
  transitionDuration: 0,
  itemSelector: '.grid-item',
  percentPosition: true,
  columnWidth: 1,
}

const Gallery = ({ galleries }) => (
  <Container className="gallery">
    <Masonry className="gallery-list" elementType="ul" options={masonryOptions}>
      {galleries &&
        galleries.map(({ imageFile, grid, hideMobile }) => (
          <li
            className={`grid-item ${grid} ${
              hideMobile ? 'd-none d-md-block' : ''
            }`}
            style={
              imageFile
                ? { backgroundImage: `url(${imageFile?.sourceUrl})` }
                : {}
            }
          />
        ))}
    </Masonry>
  </Container>
)

export default Gallery
