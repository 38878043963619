/* eslint-disable react/no-unescaped-entities, camelcase */
import React, { useEffect } from 'react'
import { Button, ButtonToolbar, Col, Row, Table } from 'react-bootstrap'
import propTypes from 'prop-types'
import moment from 'moment'
import 'moment/locale/fr'
import upperFirst from 'lodash/upperFirst'
import Heading from '../../app-components/dashed-heading'
import zipcodes from '../../../helpers/use-zipcodes'
import { getUser } from '../../../store/authentication/selectors'
import { connect } from 'react-redux'

const RecapPanel = ({
  type,
  data,
  stepsContent,
  goPrevious,
  offer,
  values,
  handleSubmit,
  isMobile,
  navigate,
  user,
}) => {
  useEffect(() => {
    if (
      (type === 'portrait' && !user?.email && offer.email === '') ||
      offer.format === ''
    ) {
      navigate('../format')
    }
  }, []) // todo put in middleware ?

  // product image
  let offerImg = ''
  if (type.toLowerCase() === 'portrait') {
    const formats = stepsContent.formats.find(
      (f) => f.formatId === offer.format
    )
    if (typeof formats !== 'undefined') {
      const {
        formatImageSvg: { sourceUrl },
      } = formats
      offerImg = sourceUrl
    }
  } else offerImg = data?.headerImageFile?.sourceUrl ?? ''
  const getAddress = (zip) => {
    const code = zipcodes.filter((o) => zip === o.recordid)
    return code !== []
      ? `${code[0].dFields.code_postal} ${code[0].dFields.libelle_d_acheminement}`
      : ''
  }
  return (
    <Row className="mb-3">
      <Col>
        <Heading align="left" title="Récapitulatif" />
        <h3 className="step-title">
          {type === 'portrait' ? (
            <>
              <span className="text-yellow">1.</span> Informations du produit
            </>
          ) : (
            <>
              <span className="text-yellow">3.</span> Votre commande{' '}
              {upperFirst(type)}
            </>
          )}
        </h3>
        <Row>
          <Col xs={6} lg={4} className="pt-0 pb-lg-4">
            {offerImg !== '' && (
              <div className="img-overlay">
                <img src={offerImg} alt={type} />
                <div className="overlay">
                  <p className="overlay-helper text-center">{type}</p>
                </div>
              </div>
            )}
          </Col>
          <Col>
            <Table>
              <tbody>
                {type === 'portrait' ? (
                  <>
                    <tr>
                      <td className="font-weight-bold">Format</td>
                      <td className="text-right">{values.format}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Fond</td>
                      <td className="text-right">{values.background}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Rendu</td>
                      <td className="text-right">{values.render}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Résolution</td>
                      <td className="text-right">{values.resolution}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Retouche</td>
                      <td className="text-right">{values.edit}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td className="font-weight-bold">Livraison</td>
                      <td className="text-right">{values.option}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Type d'événement</td>
                      <td className="text-right">{offer.eventType}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Utilisation</td>
                      <td className="text-right">{offer.usage}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <h3 className="step-title mt-3">
          <span className="text-yellow">2.</span> Information du client
        </h3>
        <Row>
          <Col md={4}>
            <p>
              <strong>
                {user?.lastname.toUpperCase() || offer.lastName.toUpperCase()}{' '}
                {upperFirst(user?.firstname || offer.firstName)}
              </strong>
              <br />
              {user?.tel || offer.tel}
              <br />
              {user?.email || offer.email}
              <br />
              {user?.position_held || offer.position}
            </p>
          </Col>
          <Col>
            <Table className="table-summary">
              <tbody>
                {type === 'portrait' && (
                  <>
                    <tr>
                      <td className="font-weight-bold">Nom de l'entreprise</td>
                      <td className="text-right">
                        {user?.companyname || offer.company}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">
                        Nombre de collaborateurs
                      </td>
                      <td className="text-right">{values.coworkers}</td>
                    </tr>
                  </>
                )}
                {(offer.zipcode || offer.address) && (
                  <tr>
                    <td className="font-weight-bold">Lieu</td>
                    <td className="text-right">
                      {type === 'portrait'
                        ? getAddress(offer.zipcode)
                        : offer.address}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="font-weight-bold">Date</td>
                  <td className="text-right">
                    {values.date
                      ? moment(values.date, 'D/M/YYYY').format('DD/MM/YYYY')
                      : 'NC'}
                  </td>
                </tr>
                {type !== 'portrait' && (
                  <>
                    <tr>
                      <td className="font-weight-bold">Durée</td>
                      <td className="text-right">{values.duration}</td>
                    </tr>
                    {offer.schedule !== '' && (
                      <tr>
                        <td className="font-weight-bold">Horaire</td>
                        <td className="text-right">{offer.schedule}</td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        {offer.observations !== '' && (
          <Row className="mt-3">
            <Col>
              <h3 className="step-title">
                <span className="text-yellow">3.</span> Observations du client
              </h3>
              <p>{offer.observations}</p>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <ButtonToolbar className="form-buttons justify-content-between">
              <Button
                variant="outline-primary"
                className="button-prev"
                size="lg"
                onClick={goPrevious}
              >
                {isMobile ? 'MODIFIER' : 'MODIFIER MA DEMANDE'}
              </Button>
              <Button
                onClick={handleSubmit}
                variant="warning"
                size="lg"
                className="button-confirm"
              >
                {isMobile ? 'CONFIRMER' : 'CONFIRMER MA DEMANDE'}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
RecapPanel.defaultProps = {
  isMobile: false,
}

RecapPanel.propTypes = {
  type: propTypes.string.isRequired,
  data: propTypes.objectOf(propTypes.any).isRequired,
  stepsContent: propTypes.objectOf(propTypes.any).isRequired,
  goPrevious: propTypes.func.isRequired,
  offer: propTypes.objectOf(propTypes.any).isRequired,
  values: propTypes.objectOf(propTypes.string).isRequired,
  handleSubmit: propTypes.func.isRequired,
  navigate: propTypes.func.isRequired,
  isMobile: propTypes.bool,
  user: propTypes.objectOf(propTypes.string).isRequired,
}
const mapStateToProps = (state) => ({
  user: getUser(state),
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(RecapPanel)
