import optionSquareImg from '../assets/images/portrait-options/portrait-page-option-format-carre.jpg'
import optionRectangleImg from '../assets/images/portrait-options/portrait-page-option-format-rectangle.jpg'

import portraitBusinessPlainImg from '../assets/images/portrait-options/format-business-debout-fond-uni.jpg'
import optionBgPlainImg from '../assets/images/portrait-options/portrait-page-option-fond-uni.jpg'
import optionBgSituImg from '../assets/images/portrait-options/portait-page-option-fond-en-situation.jpg'
import optionBgTextureImg from '../assets/images/portrait-options/portrait-page-option-fond-texture.jpg'
import optionColorImg from '../assets/images/portrait-options/portrait-page-option-couleur-couleur.jpg'
import optionColorNbImg from '../assets/images/portrait-options/portrait-page-option-couleur-noiretblanc.jpg'
import optionColorBothImg from '../assets/images/portrait-options/portrait-page-couleur-les-deux.jpg'
import optionRenderStdImg from '../assets/images/portrait-options/portrait-page-option-qualite-standard.jpg'
import optionRenderPremiumImg from '../assets/images/portrait-options/portait-page-option-qualite-premium.jpg'

import portraitBusinessImg from '../assets/images/products/portrait-business.jpg'
import portraitCvImg from '../assets/images/products/portrait-cv.jpg'
import portraitDirImg from '../assets/images/products/portrait-dirigeant.jpg'
import portraitSituImg from '../assets/images/products/portrait-situation.jpg'

export const defaultStyles = {
  formats: [
    {
      id: 1,
      name: 'Carré',
      image: optionSquareImg,
    },
    {
      id: 2,
      name: 'Rectangle',
      image: optionRectangleImg,
    },
    {
      id: 3,
      name: 'Rectangle debout',
      image: portraitBusinessPlainImg,
    },
  ],
  backgrounds: [
    {
      id: 1,
      name: 'Uni',
      colors: [
        { id: 1, hex: 'FFFFFF' },
        { id: 2, hex: '000000' },
        { id: 3, hex: 'FFCE01' },
        { id: 4, hex: 'A0A8B3' },
        { id: 5, hex: '060652' },
        { id: 6, hex: '0095B4' },
      ],
      image: optionBgPlainImg,
    },
    {
      id: 2,
      name: 'En situation',
      image: optionBgSituImg,
    },
    {
      id: 3,
      name: 'Fond texturé',
      image: optionBgTextureImg,
    },
  ],
  renders: [
    { id: 1, name: 'Couleur', image: optionColorImg },
    { id: 2, name: 'Noir et blanc', image: optionColorNbImg },
    { id: 3, name: 'Les deux', image: optionColorBothImg },
  ],
  edits: [
    {
      id: 1,
      name: 'Standard',
      description:
        'Optimisation lumière, contraste, colorimétrie et atténuation des brillances',
      image: optionRenderStdImg,
    },
    {
      id: 2,
      name: 'Premium',
      description:
        'Optimisation lumière, contraste et colorimétrie. Équilibrage teinte, nettoyage facial (rougeurs, boutons, cernes .etc)',
      image: optionRenderPremiumImg,
    },
  ],
  resolutions: [
    {
      id: 1,
      name: 'Web & réseaux sociaux',
      description: '1500pixels de bord large',
      icon: 'icon-logo-hd',
    },
    {
      id: 2,
      name: 'Print & presse',
      description: '4000pixels de bord large',
      icon: 'icon-logo-ultra-hd',
    },
  ],
}

export const styles = [
  {
    id: 1,
    type: 'portrait',
    name: 'Portrait business',
    price: 69.0,
    description:
      'Montrez-vous sous votre meilleur jour avec ce portrait plus large, utile pour vous présenter sur votre site internet, un article de presse ou un dossier de présentation.',
    image: portraitBusinessImg,
    styles: { ...defaultStyles },
    data: {
      style: 1,
      format: 3,
      background: 1,
      color: 1,
      render: 1,
      edit: 1,
      resolution: 1,
    },
  },
  {
    id: 2,
    type: 'portrait',
    name: 'Portrait "Linkedin"',
    price: 69.0,
    description:
      "Harmonisez votre photo de profil avec ce format idéal pour les réseaux sociaux, signature email ou l'organigramme de votre entreprise.",
    image: portraitCvImg,
    styles: { ...defaultStyles },
    data: {
      style: 2,
      format: 1,
      background: 1,
      render: 1,
      edit: 1,
      resolution: 1,
    },
  },
  {
    id: 3,
    type: 'portrait',
    name: 'Portrait dirigeant',
    price: 69.0,
    description:
      'Soignez votre "personal branding" avec un choix d\'ambiances et une séance sur-mesure pour correspondre à votre personnalité.',
    image: portraitDirImg,
    styles: { ...defaultStyles },
    data: {
      style: 3,
      format: 3,
      background: 1,
      render: 1,
      edit: 1,
      resolution: 1,
    },
  },
  {
    id: 4,
    type: 'portrait',
    name: 'Portrait en situation',
    price: 69.0,
    description:
      'Illustrez vous avec des portraits en accord avec votre environnement professionnel, idéal pour tous vos supports de communication.',
    image: portraitSituImg,
    styles: { ...defaultStyles },
    data: {
      style: 4,
      format: 2,
      background: 2,
      render: 1,
      edit: 1,
      resolution: 1,
    },
  },
]

export const presetsB2C = [
  {
    oc_id: 51,
    type: 'portrait',
    name: 'Portrait Business',
    price: 59,
    description:
      'Montrez-vous sous votre meilleur jour avec ce portrait plus large, utile pour vous présenter sur votre site internet, un article de presse ou un dossier de présentation.',
    image: portraitBusinessImg,
    duration: 90,
    options: {
      formats: [
        {
          oc_id: 112,
          name: 'Rectangle',
          image: optionRectangleImg,
        },
      ],
      backgrounds: [
        {
          oc_id: 213,
          name: 'En situation',
          image: optionRectangleImg,
        },
        {
          oc_id: 215,
          name: 'Fond Uni',
          image: optionBgTextureImg,
          colors: [
            { oc_id: 615, name: 'Bleu', hex: '#6e9bb3' },
            { oc_id: 616, name: 'Bleu Marine', hex: '#243c55' },
            { oc_id: 617, name: 'Gris', hex: '#646d75' },
            { oc_id: 618, name: 'Gris clair', hex: '#b8c1ce' },
            { oc_id: 619, name: 'Noir', hex: '#1c1d21' },
            { oc_id: 620, name: 'Jaune / orange', hex: '#c28124' },
            { oc_id: 621, name: 'Jaune pop', hex: '#f0cb5e' },
            { oc_id: 622, name: ' Rose pop', hex: '#d35aa0' },
            { oc_id: 623, name: 'Vert pop ', hex: '#77be7a' },
            { oc_id: 624, name: 'Bleu pop', hex: '#a6d0e8' },
            { oc_id: 625, name: ' Blanc numérique', hex: '#ffffff' },
          ],
        },
      ],
      renders: [
        {
          oc_id: 301,
          name: 'Couleur',
          image: optionColorImg,
        },
        {
          oc_id: 302,
          name: 'Noir et blanc',
          image: optionColorNbImg,
        },
        {
          oc_id: 303,
          name: 'Les deux',
          image: optionColorBothImg,
        },
      ],
      edits: [
        {
          oc_id: 401,
          name: 'Standard',
          description:
            'Optimisation lumière, contraste, colorimétrie et atténuation des brillances',
          image: optionRenderStdImg,
        },
        {
          oc_id: 402,
          name: 'Premium',
          description:
            'Optimisation lumière, contraste et colorimétrie. Équilibrage teinte, nettoyage facial (rougeurs, boutons, cernes .etc)',
          image: optionRenderPremiumImg,
        },
      ],
      resolutions: [
        {
          oc_id: 501,
          name: 'Web & réseaux sociaux',
          description: '1500pixels de bord large',
          icon: 'icon-logo-hd',
        },
        {
          oc_id: 502,
          name: 'Print & presse',
          description: '4000pixels de bord large',
          icon: 'icon-logo-ultra-hd',
        },
      ],
    },
  },
  {
    oc_id: 2,
    type: 'portrait',
    name: 'Portrait "Linkedin"',
    price: 69.0,
    description:
      "Harmonisez votre photo de profil avec ce format idéal pour les réseaux sociaux, signature email ou l'organigramme de votre entreprise.",
    image: portraitCvImg,
    duration: 90,
    options: {
      formats: [
        {
          oc_id: 112,
          name: 'Rectangle',
          image: optionRectangleImg,
        },
      ],
      backgrounds: [
        {
          oc_id: 215,
          name: 'Fond texturé',
          image: optionBgTextureImg,
        },
      ],
      renders: [
        {
          oc_id: 301,
          name: 'Couleur',
          image: optionColorImg,
        },
        {
          oc_id: 303,
          name: 'Les deux',
          image: optionColorBothImg,
        },
      ],
      edits: [
        {
          oc_id: 401,
          name: 'Standard',
          description:
            'Optimisation lumière, contraste, colorimétrie et atténuation des brillances',
          image: optionRenderStdImg,
        },
        {
          oc_id: 402,
          name: 'Premium',
          description:
            'Optimisation lumière, contraste et colorimétrie. Équilibrage teinte, nettoyage facial (rougeurs, boutons, cernes .etc)',
          image: optionRenderPremiumImg,
        },
      ],
      resolutions: [
        {
          oc_id: 501,
          name: 'Web & réseaux sociaux',
          description: '1500pixels de bord large',
          icon: 'icon-logo-hd',
        },
      ],
    },
  },
  {
    oc_id: 3,
    type: 'portrait',
    name: 'Portrait Business',
    price: 559,
    description:
      'Montrez-vous sous votre meilleur jour avec ce portrait plus large, utile pour vous présenter sur votre site internet, un article de presse ou un dossier de présentation.',
    image: portraitBusinessImg,
    duration: 90,
    options: {
      formats: [
        {
          oc_id: 112,
          name: 'Rectangle',
          image: optionRectangleImg,
        },
      ],
      backgrounds: [
        {
          oc_id: 213,
          name: 'En situation',
          image: optionRectangleImg,
        },
        {
          oc_id: 215,
          name: 'Fond texturé',
          image: optionBgTextureImg,
        },
      ],
      renders: [
        {
          oc_id: 301,
          name: 'Couleur',
          image: optionColorImg,
        },
        {
          oc_id: 302,
          name: 'Noir et blanc',
          image: optionColorNbImg,
        },
        {
          oc_id: 303,
          name: 'Les deux',
          image: optionColorBothImg,
        },
      ],
      edits: [
        {
          oc_id: 401,
          name: 'Standard',
          description:
            'Optimisation lumière, contraste, colorimétrie et atténuation des brillances',
          image: optionRenderStdImg,
        },
        {
          oc_id: 402,
          name: 'Premium',
          description:
            'Optimisation lumière, contraste et colorimétrie. Équilibrage teinte, nettoyage facial (rougeurs, boutons, cernes .etc)',
          image: optionRenderPremiumImg,
        },
      ],
      resolutions: [
        {
          oc_id: 501,
          name: 'Web & réseaux sociaux',
          description: '1500pixels de bord large',
          icon: 'icon-logo-hd',
        },
        {
          oc_id: 502,
          name: 'Print & presse',
          description: '4000pixels de bord large',
          icon: 'icon-logo-ultra-hd',
        },
      ],
    },
  },
  {
    oc_id: 4,
    type: 'portrait',
    name: 'Portrait "Linkedin"',
    price: 69.0,
    description:
      "Harmonisez votre photo de profil avec ce format idéal pour les réseaux sociaux, signature email ou l'organigramme de votre entreprise.",
    image: portraitCvImg,
    duration: 90,
    options: {
      formats: [
        {
          oc_id: 112,
          name: 'Rectangle',
          image: optionRectangleImg,
        },
      ],
      backgrounds: [
        {
          oc_id: 215,
          name: 'Fond texturé',
          image: optionBgTextureImg,
        },
      ],
      renders: [
        {
          oc_id: 301,
          name: 'Couleur',
          image: optionColorImg,
        },
        {
          oc_id: 303,
          name: 'Les deux',
          image: optionColorBothImg,
        },
      ],
      edits: [
        {
          oc_id: 401,
          name: 'Standard',
          description:
            'Optimisation lumière, contraste, colorimétrie et atténuation des brillances',
          image: optionRenderStdImg,
        },
        {
          oc_id: 402,
          name: 'Premium',
          description:
            'Optimisation lumière, contraste et colorimétrie. Équilibrage teinte, nettoyage facial (rougeurs, boutons, cernes .etc)',
          image: optionRenderPremiumImg,
        },
      ],
      resolutions: [
        {
          oc_id: 501,
          name: 'Web & réseaux sociaux',
          description: '1500pixels de bord large',
          icon: 'icon-logo-hd',
        },
      ],
    },
  },
]
