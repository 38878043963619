import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { Col, Form, Row } from 'react-bootstrap'
import moment from 'moment'
import { connect } from 'react-redux'
import ErrorMessage from '../../app-components/form-error-message'
import CustomSelect from '../../app-components/custom-select'
import { getStudiosOptions } from '../../../helpers'
import BookingCalendar from '../../app-components/booking-calendar'
import { getStudioById, getTimeslots } from '../../../store/studios/selectors'
import {
  doGetStudioListByProduct,
  doGetStudioTimeslots,
} from '../../../store/studios/actions'
import { doSelectProduct } from '../../../store/offer/actions'
import { getUser } from '../../../store/authentication/selectors'

const BookingPanel = ({
  register,
  errors,
  offer,
  handleChange,
  timeslots,
  getStudioList,
  getStudioTimeslots,
  updateProduct,
  user,
  location,
  triggerValidation,
}) => {
  const isEdit = location?.state?.edit
  const [selectedMonth, setMonth] = useState(
    offer?.date !== null || isEdit ? moment(offer?.date) : moment.utc()
  )
  const prevMonth = () => setMonth(moment(selectedMonth.subtract(1, 'month')))
  const nextMonth = () => setMonth(moment(selectedMonth.add(1, 'month')))
  const [studioList, setStudioList] = useState([])

  useEffect(() => {
    async function init() {
      setStudioList(await getStudioList(offer))
    }
    init()
  }, [offer.id])
  useEffect(() => {
    async function init() {
      if (studioList.length > 0 && offer.studio !== null) {
        await getStudioTimeslots(
          offer.studio,
          offer.id,
          selectedMonth.get('month') + 1,
          selectedMonth.get('year')
        )
      }
    }
    init()
  }, [getStudioTimeslots, offer.id, offer.studio, studioList, selectedMonth])

  useEffect(() => {
    if (user !== null) {
      triggerValidation()
    }
  }, [])
  return (
    <>
      <h3 className="step-title">
        <span className="text-yellow">6.</span> Pour terminer, réservez votre
        séance
        <span className="mandatory-label text-muted float-right d-none d-lg-inline">
          Champs obligatoires*
        </span>
      </h3>
      <p className="font-weight-bold">
        Vos coordonnées
        <span className="mandatory-label text-muted float-right d-inline d-lg-none">
          Champs obligatoires*
        </span>
      </p>
      <Form.Row className="mb-lg-3">
        <Form.Group
          as={Col}
          xs={12}
          lg
          className="form-label-group"
          controlId="lastName"
        >
          <Form.Control
            placeholder="Nom*"
            name="lastName"
            ref={register}
            defaultValue={user?.lastname || offer.lastName}
            onChange={handleChange}
            isInvalid={errors.lastName !== undefined}
          />
          <Form.Label>Nom*</Form.Label>
          <ErrorMessage name="lastName" errors={errors} />
        </Form.Group>
        <Form.Group
          as={Col}
          xs={12}
          lg
          className="form-label-group"
          controlId="firstName"
        >
          <Form.Control
            placeholder="Prénom*"
            name="firstName"
            ref={register}
            defaultValue={user?.firstname || offer.firstName}
            onChange={handleChange}
            isInvalid={errors.firstName !== undefined}
          />
          <Form.Label>Prénom*</Form.Label>
          <ErrorMessage name="firstName" errors={errors} />
        </Form.Group>
      </Form.Row>
      <Form.Row className="mb-lg-3">
        <Form.Group
          as={Col}
          xs={12}
          lg
          className="form-label-group"
          controlId="tel"
        >
          <Form.Control
            placeholder="Téléphone*"
            name="tel"
            ref={register}
            defaultValue={user?.tel || offer.tel}
            onChange={handleChange}
            isInvalid={errors.tel !== undefined}
          />
          <Form.Label>Téléphone*</Form.Label>
          <ErrorMessage name="tel" errors={errors} />
        </Form.Group>
        <Form.Group
          as={Col}
          xs={12}
          lg
          className="form-label-group"
          controlId="email"
        >
          <Form.Control
            type="email"
            placeholder="Mail*"
            name="email"
            ref={register}
            defaultValue={user?.email || offer.email}
            onChange={handleChange}
            isInvalid={errors.email !== undefined}
          />
          <Form.Label>Mail*</Form.Label>
          <ErrorMessage name="email" errors={errors} />
        </Form.Group>
      </Form.Row>
      <p className="font-weight-bold">Votre lieu de séance</p>
      <Row>
        <Col>
          <CustomSelect
            placeholder="Sélectionnez votre studio"
            options={getStudiosOptions(studioList)}
            selected={studioId => {
              updateProduct({ studio: studioId })
            }}
            defaultValue={
              studioList?.find(studio => studio.id === offer?.studio)?.id
            }
            name="studios"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BookingCalendar
            selectedStudio={studioList.length > 0 ? offer.studio : null}
            selectedDate={offer?.date === null ? moment() : moment(offer.date)}
            month={selectedMonth}
            timeslots={timeslots}
            nextMonth={nextMonth}
            prevMonth={prevMonth}
            isB2C
            setDate={d => updateProduct({ date: d })}
            setTime={time => {
              const t = time.split('H')
              updateProduct({
                date: moment(offer.date).set({ hour: t[0], minute: t[1] }),
              })
            }}
          />
        </Col>
      </Row>
    </>
  )
}

BookingPanel.propTypes = {
  register: propTypes.func.isRequired,
  errors: propTypes.objectOf(propTypes.any).isRequired,
  offer: propTypes.objectOf(propTypes.any).isRequired,
  timeslots: propTypes.arrayOf(propTypes.object).isRequired,
  getTimeslots: propTypes.func.isRequired,
  handleChange: propTypes.func.isRequired,
  getStudioList: propTypes.func.isRequired,
  getStudioTimeslots: propTypes.func.isRequired,
  updateProduct: propTypes.func.isRequired,
  user: propTypes.objectOf(propTypes.string).isRequired,
  location: propTypes.objectOf(propTypes.any).isRequired,
  triggerValidation: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  timeslots: getTimeslots(state),
  getStudio: studioId => getStudioById(state, studioId),
  user: getUser(state),
})

const mapDispatchToProps = dispatch => ({
  getStudioList: offer => dispatch(doGetStudioListByProduct(offer.id)),
  getStudioTimeslots: (studioId, productId, month, year) =>
    dispatch(doGetStudioTimeslots(studioId, productId, month, year)),
  updateProduct: product => dispatch(doSelectProduct(product)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingPanel)
