import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import Heading from '../../app-components/dashed-heading'
import Button from '../../app-components/button'
import PortfolioSlider from '../../sections/portfolio/slider'
import { connect } from 'react-redux'
import { getUser } from '../../../store/authentication/selectors'

const ConfirmStep = ({ offer, user, navigate }) => {
  useEffect(() => {
    if (!user?.email && offer.email === '') navigate('../format')
  }, [])

  return (
    <Row className="mb-3">
      <Col>
        <Heading align="left" title="Confirmation de la commande" />
        <Row className="mt-4">
          <Col md={10}>
            <h3 className="step-title">Merci de nous avoir choisi !</h3>
            <p>
              Votre demande de devis a bien été envoyée à Booké. Vous recevrez
              une réponse sous 48h à l&apos;adresse suivante
              <b> {user?.email || offer.email}</b>.
            </p>
            <Button name="Retour à l'accueil" to="/" size="lg" />
          </Col>
        </Row>
        <div className="border-bottom my-5" />
        <Row className="mt-4">
          <Col className="modal-confirm">
            <h3>À découvrir aussi…</h3>
            <PortfolioSlider imgClass="confirm" />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
ConfirmStep.propTypes = {
  offer: propTypes.objectOf(propTypes.any).isRequired,
  navigate: propTypes.func.isRequired,
  user: propTypes.objectOf(propTypes.string).isRequired,
}
const mapStateToProps = (state) => ({
  user: getUser(state),
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmStep)
