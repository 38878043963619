/* eslint-disable jsx-a11y/interactive-supports-focus
, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/no-danger  */
import React from 'react'
import Button from 'react-bootstrap/Button'
import propTypes from 'prop-types'
import './styles.scss'
import Icon from '../../app-components/icon'

class OfferItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  showDetails = () => {
    this.setState({ show: true })
  }

  hideDetails = () => {
    this.setState({ show: false })
  }

  render() {
    const { show } = this.state
    const { item, selectItem, showPrice } = this.props
    let description
    let name
    if (showPrice && item.product_description !== undefined) {
      description = item?.product_description[0].description
      name = item.product_description[0].name
    }

    const ItemIcon = ({ text }) => (
      <>
        {text && <span className="font-weight-bold text-primary">{text}</span>}
        <Icon
          name={show ? 'icon-edit-picture' : 'icon-edit-white'}
          iconClass={show ? 'ml-2' : ''}
          paths={4}
        />
      </>
    )

    return (
      <div
        className="offer-style-item"
        onMouseEnter={this.showDetails}
        onMouseLeave={this.hideDetails}
      >
        <button
          type="button"
          className="btn offer-style-item-top-btn text-white m-3 d-md-none"
          onClick={() => this.setState({ show: !show })}
        >
          <Icon name={show ? 'icon-info-selected' : 'icon-info'} paths={3} />
        </button>
        <div
          className={`overlay text-center p-4 d-flex w-100 ${
            show ? 'show' : ''
          }`}
          onClick={selectItem}
        >
          <h3 className="title">{showPrice ? name : item.name}</h3>
          {showPrice && (
            <p className="price">À partir de {item.price_formated}</p>
          )}
          {showPrice && (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          )}
          {!showPrice && <p>{item.description}</p>}
        </div>
        {show ? (
          <Button
            variant="light"
            size="sm"
            className="offer-style-item-btn bottom rounded-pill d-flex align-items-center py-1 px-2 m-3"
            onClick={selectItem}
          >
            <ItemIcon text="RÉSERVER " />
          </Button>
        ) : (
          <button
            type="button"
            className="btn offer-style-item-btn bottom m-2"
            onClick={selectItem}
          >
            <ItemIcon />
          </button>
        )}
        <img
          className="w-100"
          src={showPrice ? item.original_image : item.image}
          alt={showPrice ? name : item.name}
        />
      </div>
    )
  }
}
OfferItem.propTypes = {
  item: propTypes.objectOf(propTypes.any).isRequired,
  selectItem: propTypes.func.isRequired,
  showPrice: propTypes.bool,
}
OfferItem.defaultProps = { showPrice: false }
export default OfferItem
