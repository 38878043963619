import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import last from 'lodash/last'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { Router } from '@reach/router'
import Layout from '../layouts/default'
import SidePanel from '../components/offer/offerPanel'

import STEPS, { PORTRAIT_B2C_STEPS } from '../constants/steps'
// Mock data
import { styles as presetsB2B } from '../mock/products'
import { isB2C } from '../helpers'
import OfferProductPage from '../components/offer/offer-product-page'
import {
  getCart,
  getCategoryBySlug,
  getProductsByCategory,
  getProductType,
  getSelectedProduct,
  getTotalPrice,
} from '../store/offer/selectors'
import {
  doAddToCart,
  doGetCart,
  doGetCategoryBySlug,
  doGetProductsByCategory,
  doSelectProduct,
} from '../store/offer/actions'
import B2BAccountModal from '../components/offer/B2BAccountModal'
import { doLogout } from '../store/authentication/actions'
import { getUser } from '../store/authentication/selectors'
import { USER_GROUP } from '../constants/user'

const ProductPageTemplate = ({
  data: {
    wpPage: {
      seo,
      template: { acf },
      title,
      slug,
    },
    wp: {
      acfOptionsBookeOptions: {
        acfOptions: { stOptionsProductSteps: stepsContent },
      },
    },
  },
  location,
  navigate,
  fetchCategoryBySlug,
  fetchProductsByCategory,
  fetchCart,
  getCategory,
  getProductsByCat,
  selectProduct,
  logout,
  user,
  getType,
  selectedProduct,
}) => {
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [showB2BAccountModal, toggleB2BAccountModal] = useState(false)

  const [presetsB2C, setPresetB2C] = useState(
    isB2C(location)
      ? getProductsByCat(getCategory(`b2c_products_${slug}`)?.id)
      : null
  )
  useEffect(() => {
    async function init() {
      const category = await fetchCategoryBySlug(`b2c_products_${slug}`)
      if (category) {
        await fetchProductsByCategory(category?.id)
      }
      await fetchCart()
    }

    if (isB2C(location)) init()
  }, [])

  useEffect(() => {
    if (isB2C(location))
      setPresetB2C(getProductsByCat(getCategory(`b2c_products_${slug}`)?.id))
  }, [slug, getProductsByCat, location, getCategory])

  useEffect(() => {
    const url = location.pathname.split('/')
    if (url.length > 3 && last(url) !== '') {
      setShowSidePanel(true)
      if (
        isB2C(location) &&
        user !== null &&
        user?.type !== USER_GROUP.CLIENT
      ) {
        toggleB2BAccountModal(true)
      }
    } else {
      setShowSidePanel(false)
    }
  }, [location])

  const togglePanel = async () => {
    if (showSidePanel) {
      await navigate('./')
    } else {
      const step = isB2C(location)
        ? PORTRAIT_B2C_STEPS
        : STEPS[`${title.toUpperCase()}_STEPS`]
      await navigate(`./${Object.keys(step)[0].toLowerCase()}`)
    }
    setShowSidePanel(!showSidePanel)
  }
  // function to select product style from portrait page (fill the steps)
  const selectItem = async (index) => {
    const item = isB2C(location) ? presetsB2C[index] : presetsB2B[index]
    if (isB2C(location)) {
      selectProduct({ ...item }, true)
    } else {
      selectProduct({ ...item.data, name: item.name })
    }
    await togglePanel()
  }

  return (
    <Layout seo={seo}>
      <Router>
        <OfferProductPage
          path="/particuliers/:productType/*"
          title={title}
          acf={acf}
          togglePanel={togglePanel}
          presetsB2C={presetsB2C}
          selectItem={selectItem}
          isB2C
        />
        <OfferProductPage
          path="/professionnels/:productType/*"
          title={title}
          acf={acf}
          selectItem={selectItem}
          togglePanel={togglePanel}
        />
      </Router>
      <SidePanel
        type={title}
        productStyles={isB2C(location) ? presetsB2C : presetsB2B}
        show={showSidePanel}
        togglePanel={togglePanel}
        data={acf}
        stepsContent={stepsContent}
        STEPS={
          isB2C(location) && getType(selectedProduct) === 'portrait'
            ? PORTRAIT_B2C_STEPS
            : STEPS[
                `${
                  isB2C(location)
                    ? getType(selectedProduct)?.toUpperCase()
                    : title?.toUpperCase()
                }_STEPS`
              ]
        }
        location={location}
        isB2C={isB2C(location)}
      />
      <B2BAccountModal
        show={showB2BAccountModal}
        handleCancel={async () => {
          toggleB2BAccountModal(false)
          await togglePanel()
        }}
        handleConfirm={async () => {
          await logout()
          toggleB2BAccountModal(false)
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query ProductPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      template {
        ... on WpTemplate_Products {
          acf {
            accordionContent {
              description
              title
            }
            displayRealizations
            displayServices
            displayServices2
            gallery {
              grid
              imageFile {
                sourceUrl
                title
              }
              hideMobile
              videoFile {
                sourceUrl
                title
              }
              mediaType
            }
            headerImageFile {
              sourceUrl
              title
            }
            headerPicture {
              sourceUrl
              title
            }
            headerTitle
            productType
            services {
              ...serviceFragment
            }
            servicesBlock2 {
              ...serviceFragment
            }
            simpleTextCta {
              title
              url
            }
            simpleTextDescription
            simpleTextTitle
          }
        }
      }
      ...yoastMeta
    }
    wp {
      acfOptionsBookeOptions {
        acfOptions {
          stOptionsProductSteps {
            backgrounds {
              backgroundId
              backgroundImageSvg {
                sourceUrl
              }
              backgroundMediaType
              backgroundTitle
            }
            edits {
              editDescription
              editId
              editImageSvg {
                sourceUrl
              }
              editMediaType
              editTitle
            }
            formats {
              fieldGroupName
              formatId
              formatImageSvg {
                sourceUrl
              }
              formatMediaType
              formatTitle
            }
            renders {
              renderImageSvg {
                sourceUrl
              }
              renderId
              renderTitle
              renderMediaType
            }
            options {
              optionTitle
              optionId
            }
            resolutions {
              resolutionDescription
              resolutionId
              resolutionImageSvg {
                sourceUrl
              }
              resolutionMediaType
              resolutionTitle
            }
          }
        }
      }
    }
  }
`
ProductPageTemplate.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
  location: propTypes.objectOf(propTypes.any).isRequired,
  navigate: propTypes.func.isRequired,
  fetchCategoryBySlug: propTypes.func.isRequired,
  fetchProductsByCategory: propTypes.func.isRequired,
  fetchCart: propTypes.func.isRequired,
  getProductsByCat: propTypes.func.isRequired,
  getCategory: propTypes.func.isRequired,
  selectProduct: propTypes.func.isRequired,
  logout: propTypes.func.isRequired,
  user: propTypes.objectOf(propTypes.any),
}
ProductPageTemplate.defaultProps = { user: null }

const mapStateToProps = (state) => ({
  cart: getCart(state),
  selectedProduct: getSelectedProduct(state),
  totalPrice: () => getTotalPrice(state),
  getCategory: (slug) => getCategoryBySlug(state, slug),
  getProductsByCat: (categoryId) => getProductsByCategory(state, categoryId),
  getType: (product) => getProductType(state, product?.id),
  user: getUser(state),
})
const mapDispatchToProps = (dispatch) => ({
  addToCart: (product, quantity = 1) =>
    dispatch(doAddToCart(product, quantity)),
  selectProduct: (product, isPreset = false) =>
    dispatch(doSelectProduct(product, isPreset)),
  fetchCategoryBySlug: (slug) => dispatch(doGetCategoryBySlug(slug)),
  fetchProductsByCategory: (categoryId) =>
    dispatch(doGetProductsByCategory(categoryId)),
  fetchCart: () => dispatch(doGetCart()),
  logout: () => dispatch(doLogout(false)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ProductPageTemplate)
