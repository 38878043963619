import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import propTypes from 'prop-types'
import AccordionItem from './item'
import './styles.scss'

const OfferDropdown = ({ sections }) => {
  const [isActive, setActive] = useState('')

  return sections !== null ? (
    <Accordion className="accordion-section mt-4 pr-lg-3" activeKey={isActive}>
      {sections.map((section, index) =>
        section.title !== '' && section.description !== '' ? (
          <AccordionItem
            item={section}
            index={index+1}
            key={section.title}
            active={isActive}
            setActive={setActive}
          />
        ) : null
      )}
    </Accordion>
  ) : null
}

OfferDropdown.propTypes = {
  sections: propTypes.objectOf(propTypes.object).isRequired,
}
export default OfferDropdown
