import { Col, Container, Row } from 'react-bootstrap'
import propTypes from 'prop-types'
import React from 'react'
import Banner from '../../sections/_banner/default'
import Breadcrumbs from '../../app-components/breadcrumbs'
import SimpleText from '../../sections/simpleText'
import Button from '../../app-components/button'

const OfferProductHeader = ({ acf, title, togglePanel, isB2C }) => {
  return (
    <>
      {acf.headerTitle && (
        <Banner
          title={acf.headerTitle}
          backgroundImg={
            acf.headerImageFile ? acf.headerImageFile.sourceUrl : ''
          }
          color={acf.headerBackgroundColor}
        />
      )}
      <Container>
        <Breadcrumbs
          route={[
            { title: 'Accueil', path: '/' },
            {
              title: 'Nos offres',
              path: isB2C ? '/particuliers/' : '/professionnels/',
            },
            { title },
          ]}
        />
      </Container>
      {acf.simpleTextTitle && acf.simpleTextDescription && (
        <>
          <SimpleText
            className="heading"
            title={acf.simpleTextTitle}
            description={acf.simpleTextDescription}
          />
          {/* CTA : open side panel */}
          <Container className="mb-4">
            <Row>
              <Col className="text-center">
                <Button
                  name={acf.simpleTextCta.title}
                  onClick={togglePanel}
                />
              </Col>
            </Row>
          </Container>
          {/*  END CTA */}
        </>
      )}
    </>
  )
}
OfferProductHeader.propTypes = {
  acf: propTypes.objectOf(propTypes.any).isRequired,
  title: propTypes.string.isRequired,
  togglePanel: propTypes.func.isRequired,
  isB2C: propTypes.bool,
}
OfferProductHeader.defaultProps = { isB2C: false }

export default OfferProductHeader
