/* eslint-disable  react/prop-types */
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Heading from '../../app-components/dashed-heading'
import './styles.scss'

const SimpleText = ({ className, title, description, button }) => (
  <Container className="simple-text">
    <Row className="justify-content-center">
      <Col xs="12" md="8" lg="6" className="top-section text-center mt-4">
        <Heading
          className={className}
          title={title}
          description={description}
          button={button}
        />
      </Col>
    </Row>
  </Container>
)

export default SimpleText
