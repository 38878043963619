/* eslint-disable react/destructuring-assignment, radix,jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/interactive-supports-focus,
 jsx-a11y/no-static-element-interactions
 */
import { Form, Col } from 'react-bootstrap'
import React from 'react'
import propTypes from 'prop-types'
import he from 'he'

import Icon from '../../../app-components/icon'

import './styles.scss'
import { slug, getColorHex } from '../../../../helpers'

const ChoiceItem = ({
  item,
  optionId,
  offer,
  type,
  handleChange,
  isLast,
  isB2C,
  stepsContent,
  index,
}) => {
  let selectItem = () =>
    handleChange({
      target: {
        type: 'radio',
        value: item[`${type}Id`],
        name: type,
      },
    })

  let handleColorChange = id =>
    handleChange({ target: { name: 'color', value: id } })

  if (isB2C) {
    selectItem = () => {
      handleChange({ optionType: type, optionValue: item })
    }

    handleColorChange = color => {
      handleChange({
        optionType: `BACKGROUND ${item.name.toUpperCase()}`,
        optionValue: color,
      })
    }
  }

  const getColors = () =>
    (isB2C &&
      offer.options.find(
        o => o.name === `BACKGROUND ${item.name.toUpperCase()}`
      )) ||
    null
  const colorId = getColors()?.product_option_id

  // Renders choice's icon or image coming from Wordpress
  const renderWPImage = () => {
    const data = isB2C
      ? stepsContent[`${type}s`].find(
          s => slug(s[`${type}Title`]) === slug(he.decode(item.name))
        )
      : stepsContent[`${type}s`][index]
    if (data?.[`${type}MediaType`] === 'icon')
      return (
        <div
          className={`${
            offer[type].toString() === data[`${type}Id`] ? 'selected' : ''
          } choice-item-icon mb-2`}
          onClick={selectItem}
        >
          <Icon
            name={data[`${type}Icon`]}
            paths={data?.[`${type}IconNbPath`]}
          />
        </div>
      )
    return (
      data?.[`${type}ImageSvg`] && (
        <img
          className="choice-item-img mb-2"
          src={data?.[`${type}ImageSvg`]?.sourceUrl}
          alt={`${type}`}
          onClick={selectItem}
        />
      )
    )
  }

  return (
    <div className={`choice-item ${isLast ? '' : 'mr-2 mr-lg-3'} mb-3`}>
      {renderWPImage()}
      <Form.Check
        inline
        custom
        type="radio"
        id={`${type}-check-${
          item[`${isB2C ? 'product_option_value_id' : `${type}Id`}`]
        }`}
        name={type}
        className="d-block"
      >
        <Form.Check.Input
          type="radio"
          value={item[`${type}Id`]}
          checked={
            isB2C
              ? offer.selectedOptions[optionId] === item.product_option_value_id
              : offer[type] === item[`${type}Id`]
          }
          name={type}
          onChange={selectItem}
        />
        <Form.Check.Label className="d-block">
          {isB2C ? he.decode(item.name) : item[`${type}Title`]}
          {isB2C &&
            type === 'background' &&
            getColors() !== undefined &&
            offer.selectedOptions[optionId] ===
              item.product_option_value_id && (
              <div
                className="color-box selected float-right d-inline-block"
                style={{
                  backgroundColor: getColorHex(
                    getColors().option_value.find(
                      c =>
                        c.product_option_value_id ===
                        offer.selectedOptions[colorId]
                    )
                  ),
                }}
              />
            )}
          {(item[`${type}Description`] || item.description) && (
            <p className="product-description">
              {item[`${type}Description`]}
              {item.description}
            </p>
          )}
        </Form.Check.Label>
      </Form.Check>
      {isB2C && type === 'background' && getColors() !== undefined && (
        <>
          <div className="mt-2 color-box-select">
            <p className="color-box-select-label mb-1">Choix couleur</p>
            <Form.Row>
              {getColors().option_value.map(color => (
                <Col
                  className="mb-2"
                  md="2"
                  sm="2"
                  key={color.product_option_value_id}
                >
                  <div
                    style={{ backgroundColor: getColorHex(color) }}
                    className={
                      offer.selectedOptions[colorId] ===
                      color.product_option_value_id
                        ? 'color-box selected'
                        : 'color-box'
                    }
                    data-value={color.product_option_value_id}
                    onClick={() =>
                      offer.selectedOptions[optionId] ===
                        item.product_option_value_id && handleColorChange(color)
                    }
                  />
                </Col>
              ))}
            </Form.Row>
          </div>
        </>
      )}
    </div>
  )
}

ChoiceItem.propTypes = {
  handleChange: propTypes.func.isRequired,
  item: propTypes.objectOf(propTypes.any).isRequired,
  offer: propTypes.objectOf(propTypes.any).isRequired,
  optionId: propTypes.number,
  index: propTypes.number.isRequired,
  type: propTypes.string.isRequired,
  isLast: propTypes.bool.isRequired,
  isB2C: propTypes.bool.isRequired,
  stepsContent: propTypes.objectOf(propTypes.any).isRequired,
}
ChoiceItem.defaultProps = {
  optionId: null,
}

export default ChoiceItem
