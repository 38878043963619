import { Button, ButtonToolbar } from 'react-bootstrap'
import React from 'react'
import propTypes from 'prop-types'
import ModalCard from '../app-components/modal-card'
import Icon from '../app-components/icon'

const B2BAccountModal = ({ show, handleCancel, handleConfirm }) => {
  return (
    <ModalCard
      icon={
        <Icon name="icon-login-error" paths={9} iconClass="card-body-icon" />
      }
      title={
        <p>
          Attention, vous ne pouvez{' '}
          <strong>pas acheter un produit particulier</strong> à partir de votre{' '}
          <strong>compte professionnel</strong>
          <br />
          <br />
          Pour continuer votre achat vous devez vous{' '}
          <strong>déconnecter</strong>.
        </p>
      }
      show={show}
      heading="Produit particulier"
      className="b2b-account-modal"
      onHide={handleCancel}
    >
      <div className="mb-0 pb-0 border-t-1 border-top w-100">
        <ButtonToolbar className="form-buttons justify-content-between mt-3">
          <Button
            variant="outline-primary"
            size="lg"
            onClick={handleCancel}
            className="mx-md-2"
          >
            Annuler
          </Button>
          <Button
            onClick={handleConfirm}
            variant="primary"
            size="lg"
            className="mx-md-2"
          >
            Se déconnecter
          </Button>
        </ButtonToolbar>
      </div>
    </ModalCard>
  )
}
B2BAccountModal.propTypes = {
  show: propTypes.bool.isRequired,
  handleCancel: propTypes.func.isRequired,
  handleConfirm: propTypes.func.isRequired,
}
export default B2BAccountModal
