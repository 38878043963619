/* eslint-disable react/no-danger */
import React from 'react'
import { Accordion } from 'react-bootstrap'
import propTypes from 'prop-types'

import './styles.scss'

const DropdownItem = ({ item, index, active, setActive }) => {
  const toggle = () => {
    if (active === index) setActive('')
    else setActive(index)
  }

  return (
    <>
      <Accordion.Toggle
        className="accordion-section-title"
        as="div"
        eventKey={index}
        onClick={toggle}
      >
        <h3 className="mb-2">
          {item.title}{' '}
          <span
            className={`float-right icon-arrow-down  ${
              active === index ? 'up pl-3' : 'pr-3'
            }`}
          />
        </h3>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index}>
        <div
          className="mb-3 accordion-section-text"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </Accordion.Collapse>
    </>
  )
}

DropdownItem.propTypes = {
  item: propTypes.shape(propTypes.object).isRequired,
  index: propTypes.string.isRequired,
  active: propTypes.string.isRequired,
  setActive: propTypes.func.isRequired,
}

export default DropdownItem
