/* eslint-disable react/no-unescaped-entities, radix */
import React, { useState, useEffect } from 'react'
import { Col, Form } from 'react-bootstrap'
import moment from 'moment'
import { Typeahead } from 'react-bootstrap-typeahead'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { getUser } from '../../../store/authentication/selectors'
import CustomSelect from '../../app-components/custom-select'
import ErrorMessage from '../../app-components/form-error-message'
import Icon from '../../app-components/icon'
import {
  COWORKERS_OPTIONS,
  DURATION_OPTIONS,
  USAGE_OPTIONS,
} from '../../../constants/options'
import { getDaysOptions, getOptionList } from '../../../helpers'
import zipcodes from '../../../helpers/use-zipcodes'

const ContactForm = ({
  register,
  errors,
  offer,
  handleChange,
  setValue,
  type,
  user,
}) => {
  const [isSelected, setSelect] = useState(false)
  const [searchAddress, setAddress] = useState('')
  const sortingZipcodes = (a, b) => {
    if (searchAddress !== '') {
      if (!parseInt(searchAddress)) {
        return !a.dFields.libelle_d_acheminement.startsWith(
          searchAddress.toUpperCase()
        )
      }
      return !a.dFields.code_postal.startsWith(searchAddress)
    }
    return parseInt(a.dFields.code_postal) - parseInt(b.dFields.code_postal)
  }

  return (
    <div>
      <h3 className="step-title">
        <span className="text-yellow">6.</span> Pour terminer, remplissez ce
        formulaire
        <span className="mandatory-label text-muted float-right d-none d-lg-inline">
          Champs obligatoires*
        </span>
      </h3>
      <p className="font-weight-bold">
        Vos coordonnées
        <span className="mandatory-label text-muted float-right d-inline d-lg-none">
          Champs obligatoires*
        </span>
      </p>
      <Form.Row className="mb-lg-3">
        <Form.Group
          as={Col}
          xs={12}
          lg
          className="form-label-group"
          controlId="lastName"
        >
          <Form.Control
            placeholder="Nom*"
            name="lastName"
            ref={register}
            defaultValue={user?.lastname}
            onChange={handleChange}
            isInvalid={typeof errors.lastName !== 'undefined'}
          />
          <Form.Label>Nom*</Form.Label>
          <ErrorMessage name="lastName" errors={errors} />
        </Form.Group>
        <Form.Group
          as={Col}
          xs={12}
          lg
          className="form-label-group"
          controlId="firstName"
        >
          <Form.Control
            placeholder="Prénom*"
            name="firstName"
            ref={register}
            defaultValue={user?.firstname}
            onChange={handleChange}
            isInvalid={typeof errors.firstName !== 'undefined'}
          />
          <Form.Label>Prénom*</Form.Label>
          <ErrorMessage name="firstName" errors={errors} />
        </Form.Group>
      </Form.Row>
      <Form.Row className="mb-lg-3">
        <Form.Group
          as={Col}
          xs={12}
          lg
          className="form-label-group"
          controlId="tel"
        >
          <Form.Control
            placeholder="Téléphone*"
            name="tel"
            ref={register}
            defaultValue={user?.tel?.replace('+33', '0')}
            onChange={handleChange}
            isInvalid={typeof errors.tel !== 'undefined'}
          />
          <Form.Label>Téléphone*</Form.Label>
          <ErrorMessage name="tel" errors={errors} />
        </Form.Group>
        <Form.Group
          as={Col}
          xs={12}
          lg
          className="form-label-group"
          controlId="email"
        >
          <Form.Control
            type="email"
            placeholder="Mail*"
            name="email"
            ref={register}
            defaultValue={user?.email}
            onChange={handleChange}
            isInvalid={typeof errors.email !== 'undefined'}
          />
          <Form.Label>Mail*</Form.Label>
          <ErrorMessage name="email" errors={errors} />
        </Form.Group>
      </Form.Row>
      <Form.Row className="mb-lg-3">
        <Form.Group
          as={Col}
          xs={12}
          lg={12}
          className="form-label-group"
          controlId="position"
        >
          <Form.Control
            placeholder="Poste occupé*"
            name="position"
            ref={register}
            defaultValue={user?.position_held}
            onChange={handleChange}
          />
          <Form.Label>Poste occupé*</Form.Label>
        </Form.Group>
      </Form.Row>
      <Form.Row className="mb-lg-3">
        <Form.Group
          as={Col}
          xs={12}
          lg
          className="form-label-group"
          controlId="company"
        >
          <Form.Control
            placeholder="Nom de l'entreprise*"
            name="company"
            ref={register}
            defaultValue={user?.companyname || offer.company}
            onChange={handleChange}
            isInvalid={typeof errors.company !== 'undefined'}
          />
          <Form.Label>Nom de l'entreprise*</Form.Label>
          <ErrorMessage name="company" errors={errors} />
        </Form.Group>
      </Form.Row>
      {type === 'portrait' && (
        <Form.Row className="mb-lg-3">
          <Form.Group as={Col} xs={12} lg controlId="coworkers">
            <CustomSelect
              name="coworkers"
              register={register}
              setValue={setValue}
              defaultValue={offer.coworkers}
              options={COWORKERS_OPTIONS}
              placeholder="Nombre de collaborateurs"
              selected={handleChange}
              additionalClass="w-100"
            />
          </Form.Group>
        </Form.Row>
      )}
      <p className="font-weight-bold mt-3 mb-0 mb-lg-3 mt-lg-0">
        Lieu et{' '}
        {`${
          type === 'portrait' ? 'date de la séance' : "horaire de l'événement"
        }`}
      </p>
      {type === 'portrait' ? (
        <Form.Row>
          <Form.Group
            as={Col}
            xs={12}
            lg={6}
            onChange={(e) => setAddress(e.target.value)}
            className="form-label-group select"
            controlId="zipcode"
          >
            <Typeahead
              name="zipcode"
              id="zipcode"
              onChange={(option) => {
                handleChange({
                  target: {
                    value: option.length > 0 ? option[0].recordid : '',
                    name: 'zipcode',
                  },
                })
              }}
              ref={register}
              selected={zipcodes.filter((o) => offer.zipcode === o.recordid)}
              isInvalid={typeof errors.zipcode !== 'undefined'}
              options={zipcodes.sort(sortingZipcodes)}
              labelKey={(option) =>
                `${option.dFields.code_postal} ${option.dFields.libelle_d_acheminement}`
              }
              filterBy={(option, props) =>
                // eslint-disable-next-line react/prop-types
                option.dFields.code_postal.indexOf(props.text) !== -1 ||
                option.dFields.libelle_d_acheminement.indexOf(
                  // eslint-disable-next-line react/prop-types
                  props.text.toUpperCase()
                ) !== -1
              }
              emptyLabel="Aucune ville trouvée."
              renderInput={({
                inputRef,
                referenceElementRef,
                ...inputProps
              }) => (
                <Form.Control
                  {...inputProps}
                  autoComplete="off"
                  onFocus={() => setSelect(true)}
                  onBlur={(item) => {
                    if (item.target.value === '') setSelect(false)
                  }}
                  ref={(input) => {
                    inputRef(input)
                    referenceElementRef(input)
                  }}
                />
              )}
            />
            <Form.Label
              className={
                offer.zipcode !== '' || isSelected ? 'is-exponant' : ''
              }
            >
              Code postal ou ville
            </Form.Label>
            <Icon name="icon-arrow-down select-arrow" />
            <ErrorMessage name="zipcode" errors={errors} />
          </Form.Group>
          <Col>
            <CustomSelect
              placeholder="AAAA"
              name="year"
              register={register}
              setValue={setValue}
              defaultValue={offer.year}
              options={getOptionList(
                moment().year(),
                moment().add(10, 'years').year()
              )}
              selected={handleChange}
              additionalClass="w-100"
            />
          </Col>
          <Col>
            <CustomSelect
              name="month"
              placeholder="MM"
              register={register}
              setValue={setValue}
              defaultValue={offer.month}
              options={getOptionList(1, 13)}
              selected={handleChange}
              additionalClass="w-100"
            />
          </Col>
          <Col className="form-label-group">
            <CustomSelect
              name="day"
              register={register}
              setValue={setValue}
              defaultValue={offer.day}
              options={getDaysOptions(offer.month, offer.year)}
              placeholder="JJ"
              selected={handleChange}
              additionalClass="w-100"
            />
          </Col>
        </Form.Row>
      ) : (
        <>
          <Form.Row className="mb-lg-3">
            <Form.Group
              as={Col}
              xs={12}
              lg={12}
              className="form-label-group"
              controlId="address"
            >
              <Form.Control
                placeholder="Adresse du lieu*"
                name="address"
                ref={register}
                defaultValue={offer.address}
                onChange={handleChange}
                isInvalid={typeof errors.address !== 'undefined'}
              />
              <Form.Label>Adresse du lieu*</Form.Label>
              <ErrorMessage name="address" errors={errors} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              xs={12}
              lg={6}
              className="form-label-group"
              controlId="eventType"
            >
              <Form.Control
                placeholder="Type d'événement"
                name="eventType"
                ref={register}
                defaultValue={offer.eventType}
                onChange={handleChange}
                isInvalid={typeof errors.eventType !== 'undefined'}
              />
              <Form.Label>Type d'événement</Form.Label>
            </Form.Group>
            <Form.Group as={Col} xs={12} lg={6} controlId="duration">
              <CustomSelect
                name="duration"
                placeholder="Durée du reportage*"
                register={register}
                setValue={setValue}
                defaultValue={offer.duration}
                options={DURATION_OPTIONS}
                selected={handleChange}
                additionalClass="w-100"
              />
            </Form.Group>
            <Col>
              <CustomSelect
                placeholder="AAAA"
                name="year"
                register={register}
                setValue={setValue}
                defaultValue={offer.year}
                options={getOptionList(
                  moment().year(),
                  moment().add(10, 'years').year()
                )}
                selected={handleChange}
                additionalClass="w-100"
              />
            </Col>
            <Col>
              <CustomSelect
                name="month"
                placeholder="MM"
                register={register}
                setValue={setValue}
                defaultValue={
                  offer.month
                    ? offer.month.toString().padStart(2, '0')
                    : offer.month
                }
                options={getOptionList(1, 13)}
                selected={handleChange}
                additionalClass="w-100"
              />
            </Col>
            <Col className="form-label-group">
              <CustomSelect
                name="day"
                register={register}
                setValue={setValue}
                defaultValue={
                  offer.day ? offer.day.toString().padStart(2, '0') : offer.day
                }
                options={getDaysOptions(offer.month)}
                placeholder="JJ"
                selected={handleChange}
                additionalClass="w-100"
              />
            </Col>
            <Form.Group
              as={Col}
              xs={12}
              lg={6}
              className="form-label-group"
              controlId="schedule"
            >
              <Form.Control
                placeholder="Horaire"
                name="schedule"
                ref={register}
                defaultValue={offer.schedule}
                onChange={handleChange}
                isInvalid={typeof errors.schedule !== 'undefined'}
              />
              <Form.Label>Horaire</Form.Label>
              <ErrorMessage name="schedule" errors={errors} />
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              lg={12}
              className="form-label-group"
              controlId="usage"
            >
              <CustomSelect
                name="usage"
                register={register}
                setValue={setValue}
                defaultValue={offer.usage}
                options={USAGE_OPTIONS}
                placeholder="Usage des photos"
                selected={handleChange}
                additionalClass="w-100"
              />
            </Form.Group>
          </Form.Row>
        </>
      )}
      <p className="font-weight-bold  mt-4 mb-2 mb-lg-3 mt-lg-0">
        Vos observations
      </p>
      <Form.Row>
        <Col>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="Besoin d'autre chose ?"
            name="observations"
            ref={register}
            defaultValue={offer.observations}
            onChange={handleChange}
          />
        </Col>
      </Form.Row>
    </div>
  )
}
ContactForm.propTypes = {
  register: propTypes.func.isRequired,
  errors: propTypes.objectOf(propTypes.any).isRequired,
  offer: propTypes.objectOf(propTypes.any).isRequired,
  handleChange: propTypes.func.isRequired,
  setValue: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  user: propTypes.objectOf(propTypes.string).isRequired,
}

const mapStateToProps = (state) => ({
  user: getUser(state),
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
