import { graphql, useStaticQuery } from 'gatsby'
//
// export default () => {
//   const {
//     allCodeInseePostauxGeoflarJson: { nodes: zipcodes },
//   } = useStaticQuery(graphql`
//     query ZipCodesQuery {
//       allCodeInseePostauxGeoflarJson {
//         nodes {
//           recordid
//           dFields {
//             code_postal
//             population
//             libelle_d_acheminement
//           }
//         }
//       }
//     }
//   `)
//
//   return zipcodes
// }
import zipcodes from '../data/code-insee-postaux-geoflar.json'
export default zipcodes
