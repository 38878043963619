/* eslint-disable react/no-array-index-key, camelcase */
import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { Router } from '@reach/router'
import propTypes from 'prop-types'
import ContactFormPanel from './_contactForm'
import FormStep from './form-step'
import BookingPanel from './_bookingPanel'

const FormSteps = ({
  data,
  step,
  offer,
  handleInputChange,
  handleOptionChange,
  STEPS,
  stepsContent,
  prevStep,
  nextStep,
  register,
  errors,
  setValue,
  validate,
  triggerValidation,
  isMobile,
  isDisabled,
  type,
  validForm,
  isB2C,
}) => {
  const getStepData = stepName => {
    if (data !== undefined) {
      const optionData = data.find(
        option => option.name === stepName.toUpperCase()
      )

      return optionData === undefined ? null : optionData
    }
    return null
  }

  return (
    <>
      <Router
        basepath={`/${isB2C ? 'particuliers' : 'professionnels'}/:productType`}
        primary={false}
      >
        <FormStep
          path="format"
          step={step}
          stepsContent={stepsContent}
          title="Sélectionnez un format"
          data={isB2C ? getStepData('format') : stepsContent.formats}
          type="format"
          handleChange={isB2C ? handleOptionChange : handleInputChange}
          offer={offer}
          isB2C={isB2C}
        />
        <FormStep
          path="fond"
          step={step}
          stepsContent={stepsContent}
          title="Sélectionnez un fond"
          data={isB2C ? getStepData('background') : stepsContent.backgrounds}
          type="background"
          handleChange={isB2C ? handleOptionChange : handleInputChange}
          offer={offer}
          isB2C={isB2C}
        />
        <FormStep
          path="rendu"
          step={step}
          stepsContent={stepsContent}
          title="Sélectionnez un type de rendu"
          data={isB2C ? getStepData('render') : stepsContent.renders}
          type="render"
          handleChange={isB2C ? handleOptionChange : handleInputChange}
          offer={offer}
          isB2C={isB2C}
        />
        <FormStep
          path="retouche"
          step={step}
          stepsContent={stepsContent}
          title="Sélectionnez un type de retouche"
          data={isB2C ? getStepData('edit') : stepsContent.edits}
          type="edit"
          handleChange={isB2C ? handleOptionChange : handleInputChange}
          offer={offer}
          isB2C={isB2C}
        />
        <FormStep
          path="resolution"
          step={step}
          stepsContent={stepsContent}
          title="Sélectionnez un type de résolution"
          data={isB2C ? getStepData('resolution') : stepsContent.resolutions}
          type="resolution"
          handleChange={isB2C ? handleOptionChange : handleInputChange}
          offer={offer}
          isB2C={isB2C}
        />
        <FormStep
          path="options"
          step={step}
          stepsContent={stepsContent}
          title="Sélectionnez une option"
          data={isB2C ? getStepData('option') : stepsContent.options}
          type="option"
          handleChange={isB2C ? handleOptionChange : handleInputChange}
          offer={offer}
          isB2C={isB2C}
        />

        {isB2C ? (
          <BookingPanel
            path="seance"
            offer={offer}
            register={register}
            errors={errors}
            handleChange={handleInputChange}
            triggerValidation={triggerValidation}
            setValue={setValue}
          />
        ) : (
          <ContactFormPanel
            path="informations"
            offer={offer}
            register={register}
            errors={errors}
            type={type}
            handleChange={handleInputChange}
            setValue={setValue}
          />
        )}
      </Router>
      <ButtonToolbar className="form-buttons justify-content-between mt-4">
        {step > 0 ? (
          <Button
            variant="outline-primary"
            onClick={prevStep}
            size="lg"
            className="button-prev"
          >
            {isMobile ? 'PRÉCÉDENT' : 'ÉTAPE PRÉCÉDENTE'}
          </Button>
        ) : (
          <div />
        )}

        {step === STEPS.INFORMATIONS || step === STEPS.BOOKING ? (
          <Button
            variant="primary"
            disabled={isDisabled()}
            size="lg"
            onClick={validForm}
          >
            {isB2C ? 'AJOUTER À MA COMMANDE' : `FAIRE UNE DEMANDE`}
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={nextStep}
            size="lg"
            disabled={isDisabled()}
          >
            {isMobile ? 'SUIVANT' : 'ÉTAPE SUIVANTE'}
          </Button>
        )}
      </ButtonToolbar>
    </>
  )
}

FormSteps.defaultProps = { isMobile: false }
FormSteps.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
  step: propTypes.number.isRequired,
  offer: propTypes.objectOf(propTypes.any).isRequired,
  handleInputChange: propTypes.func.isRequired,
  handleOptionChange: propTypes.func.isRequired,
  STEPS: propTypes.objectOf(propTypes.number).isRequired,
  stepsContent: propTypes.objectOf(propTypes.any).isRequired,
  prevStep: propTypes.func.isRequired,
  nextStep: propTypes.func.isRequired,
  register: propTypes.func.isRequired,
  setValue: propTypes.func.isRequired,
  errors: propTypes.objectOf(propTypes.object).isRequired,
  validate: propTypes.objectOf(propTypes.any).isRequired,
  triggerValidation: propTypes.func.isRequired,
  isMobile: propTypes.bool,
  isDisabled: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  validForm: propTypes.func.isRequired,
  isB2C: propTypes.bool.isRequired,
}

export default FormSteps
