/* eslint-disable react/destructuring-assignment, jsx-a11y/click-events-have-key-events,
 jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid ,no-restricted-syntax, no-return-assign */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import MediaQuery from 'react-responsive'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import propTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { navigate } from '@reach/router'
import moment from 'moment'
import { getUser } from '../../../store/authentication/selectors'

import DesktopPanel from './desktop-panel'
import MobilePanel from './mobile-panel'
import zipcodes from '../../../helpers/use-zipcodes'

import 'rc-steps/assets/index.css'
import '../styles.scss'
import { slug, trackGTM } from '../../../helpers'
import {
  PORTRAIT_B2C_STEPS,
  PORTRAIT_STEPS,
  REPORTAGE_STEPS,
  STEPS_GTM_LABEL,
  STEPS_GTM_VALUE,
  STEPS_LABEL,
} from '../../../constants/steps'
import {
  doAddToCart,
  doCreateLead,
  doEmptyCart,
  doSelectProduct,
  doUpdateCartBooking,
} from '../../../store/offer/actions'
import {
  getCart,
  getProductType,
  getSelectedProduct,
  getTotalPrice,
} from '../../../store/offer/selectors'
import CartWipeModal from './CartWipeModal'

const SidePanel = ({
  selectedProduct,
  updateProduct,
  type,
  productStyles,
  show,
  user,
  togglePanel,
  data,
  STEPS,
  stepsContent,
  location,
  isB2C,
  cart,
  addToCart,
  emptyCart,
  updateCartBooking,
  createLead,
  totalPrice,
  getType,
}) => {
  const [step, setStep] = useState(0)
  // Toggle style choice panel
  const [showStylePanelDesktop, toggleStylePanelDesktop] = useState(true)
  const [showStylePanelMobile, toggleStylePanelMobile] = useState(false)
  const [showCartModal, toggleCartModal] = useState(false)
  const isPortrait =
    type === 'Portrait' || (isB2C && getType(selectedProduct) === 'portrait')

  // check if button Next is Disabled on steps
  const isDisabled = () => {
    const {
      format,
      background,
      render,
      edit,
      resolution,
      option,
      firstName,
      lastName,
      email,
      tel,
      position,
      company,
      address,
      duration,
      studio,
      date,
    } = selectedProduct
    switch (step) {
      case STEPS.FORMAT:
        return format === ''
      case STEPS.BACKGROUND:
        return background === ''
      case STEPS.RENDER:
        return render === ''
      case STEPS.EDIT:
        return edit === ''
      case STEPS.RESOLUTION:
        return resolution === ''
      case STEPS?.OPTIONS:
        return option === ''
      case STEPS?.INFORMATIONS:
      case STEPS?.BOOKING: {
        if (!isB2C) {
          // B2B
          if (user === null) {
            if (
              firstName === '' ||
              lastName === '' ||
              email === '' ||
              tel === ''
            )
              return true
          }
          if (user !== null) {
            if (
              firstName === null ||
              lastName === null ||
              !(user.tel !== null || tel !== '')
            )
              return true
          }
          if (isPortrait) {
            if (user !== null && (user.position === '' || user.company === ''))
              return true
            if (user === null && (position === '' || company === ''))
              return true
          } else if (address === '' || duration === '') {
            return true
          }

          // all good
          return false
        }
        // B2C BOOKING
        if (user === null) {
          return (
            firstName === '' ||
            lastName === '' ||
            email === '' ||
            tel === '' ||
            studio === null ||
            moment(date)?.hour() === 0
          )
        }

        return (
          (tel === '' && user.tel === null) ||
          studio === null ||
          moment(date)?.hour() === 0
        )
      }
      default:
        return true
    }
  }

  // form validation Schema
  let schema = {
    lastName: yup
      .string()
      .matches(/^[a-z-éèïë ]+$/iu)
      .max(50)
      .required(),
    firstName: yup
      .string()
      .matches(/^[a-z-éèïë ]+$/iu)
      .max(50)
      .required(),
    tel: yup
      .string()
      .required()
      .matches(/0[0-9]{9}/)
      .max(10),
    email: yup.string().required().email().max(100),
  }

  if (!isB2C) {
    schema = {
      ...schema,
      position: yup.string().max(25),
      company: yup.string().required().max(25),
      observations: yup.string().max(300),
    }
    if (isPortrait) {
      schema = {
        ...schema,
        zipcode: yup.number().integer().positive().max(99999),
      }
    } else {
      schema = {
        ...schema,
        address: yup.string().max(200).required(),
        duration: yup.string().required(),
        schedule: yup.lazy((val) =>
          !val ? yup.string() : yup.string().matches(/^\d{2}:\d{2}$/)
        ),
        eventType: yup.string().max(100),
      }
    }
  }

  if (!isPortrait) {
    schema = {
      ...schema,
      duration: yup.string().required(),
      schedule: yup.lazy((val) =>
        !val ? yup.string() : yup.string().matches(/^\d{2}:\d{2}$/)
      ),
      eventType: yup.string().max(100),
    }
  }
  const formSchema = yup.object().shape(schema)

  // initialize form for validation
  const { register, errors, formState, setValue, triggerValidation } = useForm({
    validationSchema: formSchema,
    mode: 'onChange',
    defaultValues: {
      email: user?.email || selectedProduct.email,
      firstName: user?.firstname || selectedProduct.firstName,
      lastName: user?.lastname || selectedProduct.lastName,
      tel: user?.tel?.replace('+33', '0') || selectedProduct.tel,
      position: user?.position_held || selectedProduct.position,
      company: user?.companyname || selectedProduct.company,
      address: user?.address || selectedProduct.address,
      duration: selectedProduct.duration,
      date: selectedProduct.date,
    },
  })

  // update form step when URL changes
  useEffect(() => {
    const path = location.pathname.split('/')
    const stepName = path[path.length - 1]
    const stepKey = Object.keys(STEPS_LABEL).find((key) => {
      return slug(STEPS_LABEL[key].toLowerCase()) === stepName.toLowerCase()
    })
    if (stepKey !== undefined) setStep(stepType()[stepKey])
  }, [location.pathname, STEPS])

  useEffect(() => {
    if (step >= STEPS?.INFORMATIONS) toggleStylePanelDesktop(false)
  }, [step])

  // Handle state modification on Form changes
  const handleInputChange = ({ target: { value, name } }) => {
    updateProduct({ [name]: value })
    setValue(name, value, true)
  }

  const handleOptionChange = ({ optionType, optionValue }) => {
    const { product_option_id: optionId } = selectedProduct.options.find(
      (o) => o.name === optionType.toUpperCase()
    )

    const { price, product_option_value_id: valueId } = optionValue

    updateProduct({
      [optionType]: valueId,
      extraPrice: {
        ...selectedProduct.extraPrice,
        [optionType]: parseFloat(price),
      },
      selectedOptions: {
        ...selectedProduct.selectedOptions,
        [optionId]: valueId,
      },
    })
  }
  const getValues = () => {
    // return litteral values of product option selection  (temporarly used on recap page & sellsy & email)
    const values = {}
    for (const [key, val] of Object.entries(selectedProduct)) {
      if (
        stepsContent[`${key}s`] !== undefined &&
        stepsContent[`${key}s`] !== null &&
        val !== ''
      ) {
        const value = stepsContent[`${key}s`].find(
          (e) => e[`${key}Id`] === selectedProduct[key]
        )
        values[key] = value?.[`${key}Title`]
      }
    }

    if (selectedProduct.coworkers === '') values.coworkers = 'N/C'
    else if (selectedProduct.coworkers === '1')
      values.coworkers = 'entre 0 et 50'
    else if (selectedProduct.coworkers === '2')
      values.coworkers = 'entre 50 et 100'
    else if (selectedProduct.coworkers === '3')
      values.coworkers = 'entre 100 et 350'
    else values.coworkers = '350 et plus'

    values.duration =
      selectedProduct.duration !== '' ? `${selectedProduct.duration}H` : ''

    values.date =
      selectedProduct.day !== '' &&
      selectedProduct.month !== '' &&
      selectedProduct.year !== ''
        ? `${selectedProduct.day}/${selectedProduct.month}/${selectedProduct.year}`
        : null

    return values
  }

  const getZip = (id) => {
    if (type === 'Portrait') {
      const zip = zipcodes.filter((z) => z.recordid === id)
      return zip !== undefined && zip.length > 0 ? zip[0].dFields : ''
    }
    return false
  }

  // Render vertical side button
  const renderSideBtn = () => (
    <div className="side-button-wrapper d-none d-md-inline-block">
      <Button
        variant="primary"
        onClick={() => {
          if (isPortrait) updateProduct(productStyles[0], true)
          togglePanel()
        }}
        className="side-button"
      >
        Devis en ligne
      </Button>
    </div>
  )

  const stepType = () => {
    if (isPortrait) {
      return isB2C ? PORTRAIT_B2C_STEPS : PORTRAIT_STEPS
    }
    return REPORTAGE_STEPS
  }

  const nextStep = async () => {
    const values = getValues()
    const productType = isB2C ? getType(selectedProduct) : type.toLowerCase()

    let trackObj = {
      event: 'tunnel',
      products: {
        requestType: productType,
        step: STEPS_GTM_LABEL[Object.keys(stepType())[step]],
      },
    }
    if (isPortrait && Object.keys(stepType())[step] !== 'INFORMATIONS') {
      trackObj = {
        ...trackObj,
        products: {
          ...trackObj.products,
          choice:
            STEPS_GTM_VALUE[productType][step][Object.values(values)[step]],
        },
      }
    }

    if (!isPortrait) {
      trackObj = {
        ...trackObj,
        products: {
          ...trackObj.products,
          typeRequest: selectedProduct.eventType.toLowerCase(),
          usage: STEPS_GTM_VALUE[productType][step][selectedProduct.usage],
        },
      }
    }

    trackGTM(trackObj)

    setStep(step + 1)
    await navigate(`./${slug(STEPS_LABEL[Object.keys(stepType())[step + 1]])}`)
  }
  const prevStep = async () => {
    setStep(step - 1)
    await navigate(
      `./${slug(STEPS_LABEL?.[Object.keys(stepType())[step - 1]])}`
    )
  }

  // check if step is fullfilled before change
  const goStep = async (stepToGo) => {
    if (stepToGo > step && isDisabled()) return
    setStep(stepToGo)
    await navigate(`./${slug(STEPS_LABEL[Object.keys(stepType())[stepToGo]])}`)
  }

  // trigger validation on formStep
  const handleB2CSubmit = async (isReset = false, isEdit = false) => {
    if (isReset) {
      // remove actual product from cart
      const reset = await emptyCart()
      if (!reset) return false
    }
    if (isEdit) {
      // change booking
      updateCartBooking({
        studio: selectedProduct.studio,
        date: selectedProduct.date,
      })
      // return to cart
      return navigate('/reservation')
    }
    // add Product to Cart
    const success = await addToCart(selectedProduct, 1)
    // redirect to CART
    if (success === true) {
      return navigate('/reservation/', {
        state: { showModal: true },
      })
    }
    // todo handle error
    return false
  }

  const validForm = async () => {
    if (
      (step === STEPS.INFORMATIONS && formState.dirty) ||
      step === STEPS.BOOKING
    ) {
      await triggerValidation()

      if (isEmpty(errors)) {
        // B2C
        if (isB2C) {
          if (cart.products.length > 0 && !location?.state?.edit) {
            toggleCartModal(true)
          } else {
            await handleB2CSubmit(false, location?.state?.edit || false)
          }
        } else {
          // B2B
          await nextStep()
        }
      }
    }
  }

  const handleSubmit = async () => {
    const success = await createLead(
      user,
      selectedProduct,
      type,
      getValues,
      getZip
    )
    if (success) {
      setStep(STEPS.CONFIRM)
      await navigate(`./confirmation`)
    }
  }

  const panelProps = {
    data,
    productStyles:
      (!isB2C && type === 'Portrait') || isB2C ? productStyles : null,
    product: selectedProduct,
    productName: () => {
      if (isB2C) return selectedProduct?.product_description?.[0].name
      return isPortrait && selectedProduct?.name !== undefined
        ? selectedProduct.name
        : type
    },
    totalPrice,
    step,
    offer: selectedProduct,
    selectOffer: (o, isPreset = false) => {
      updateProduct(o, isPreset)
      if (isPreset) toggleStylePanelDesktop(true)
    },
    productType: isB2C ? getType(selectedProduct) : type.toLowerCase(),
    handleInputChange,
    handleOptionChange,
    showPanel: show,
    togglePanel,
    showStylePanel: showStylePanelDesktop,
    validForm,
    toggleStylePanel: () => {
      if (step < STEPS.INFORMATIONS)
        toggleStylePanelDesktop(!showStylePanelDesktop)
    },
    STEPS,
    stepsContent,
    prevStep,
    nextStep,
    goStep,
    errors,
    validate: formState,
    triggerValidation,
    register,
    handleSubmit,
    values: getValues,
    setValue,
    isDisabled,
    isB2C,
  }

  // render panel accordingly to device
  const renderPanel = () => (
    <>
      <MediaQuery minWidth={992}>
        <DesktopPanel {...panelProps} />
      </MediaQuery>
      <MediaQuery maxWidth={991} maxDeviceWidth={991}>
        <MobilePanel
          {...panelProps}
          showStylePanel={showStylePanelMobile}
          toggleStylePanel={() => toggleStylePanelMobile(!showStylePanelMobile)}
        />
      </MediaQuery>
      <CartWipeModal
        show={showCartModal}
        handleCancel={async () => {
          toggleCartModal(false)
          await navigate('/reservation')
        }}
        handleConfirm={async () => {
          await handleB2CSubmit(true)
          toggleCartModal(false)
        }}
      />
    </>
  )

  return !show ? renderSideBtn() : renderPanel()
}

SidePanel.propTypes = {
  type: propTypes.string.isRequired,
  show: propTypes.bool.isRequired,
  togglePanel: propTypes.func.isRequired,
  user: propTypes.objectOf(propTypes.any),
  data: propTypes.objectOf(propTypes.any).isRequired,
  stepsContent: propTypes.objectOf(propTypes.any).isRequired,
  STEPS: propTypes.objectOf(propTypes.number).isRequired,
  isB2C: propTypes.bool.isRequired,
  cart: propTypes.objectOf(propTypes.any).isRequired,
  addToCart: propTypes.func.isRequired,
  emptyCart: propTypes.func.isRequired,
  updateProduct: propTypes.func.isRequired,
  updateCartBooking: propTypes.func.isRequired,
  createLead: propTypes.func.isRequired,
  totalPrice: propTypes.func.isRequired,
  getType: propTypes.func.isRequired,
}
SidePanel.defaultProps = { user: null }

const mapStateToProps = (state) => ({
  user: getUser(state),
  selectedProduct: getSelectedProduct(state),
  totalPrice: () => getTotalPrice(state),
  cart: getCart(state),
  getType: (product) => getProductType(state, product?.id),
})

const mapDispatchToProps = (dispatch) => ({
  addToCart: (product, quantity) => dispatch(doAddToCart(product, quantity)),
  emptyCart: () => dispatch(doEmptyCart()),
  updateProduct: (product, isPreset) =>
    dispatch(doSelectProduct(product, isPreset)),
  updateCartBooking: (product) => dispatch(doUpdateCartBooking(product)),
  createLead: (user, product, type, getValues, getZip) =>
    dispatch(doCreateLead(user, product, type, getValues, getZip)),
})
export default connect(mapStateToProps, mapDispatchToProps)(SidePanel)
